// MapComponent.jsx
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css";
import { NavLink } from "react-router-dom"; // Import from react-router-dom

const MapComponents = ({ data }) => {
  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    iconRetinaUrl:
      "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  });

  return (
    <MapContainer
      center={[data[0].latitute, data[0].longitute]}
      zoom={12}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {data.map((marker, index) => (
        <Marker key={index} position={[marker.latitute, marker.longitute]}>
          <Popup>
            {/* <NavLink to={`/list-home-LandingPage/${homelist.stayId}`}>  */}
              <i className="fa-solid fa-house-user"></i> 
              <b>{marker.homeName}</b>
            {/* </NavLink> */}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponents;
