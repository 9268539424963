import React from "react";
import "aos/dist/aos.css";
import "../App.css";
import "./Home.scss";
const Steps = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="steps-heading">
              {/* <span className="fa fa-star checked stepstar"> </span>{" "} */}
              {/* <span style={{ color: "#61ce70", fontSize: "18px" }}>Steps</span> */}
              <h2 className="headingsteps">
              Steps to finding the best place for your loved one!
              </h2>
            </div>
            <div
              className="steps-data"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="step1 d-flex">
                <div className="step-data-img">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721019702/calling-gif-150x150_ts358z.gif"
                    alt=""
                  />
                </div>
                <div className="step-data-content">
                  <h3>Contact</h3>
                  <p>
                    Contact Our Free Senior Placement Specialist for an in-depth
                    personalized interview to assess your care needs.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="steps-data"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="step1 d-flex">
                <div className="step-data-img">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721019807/filter-gif-150x150_grxxqe.gif"
                    alt=""
                  />
                </div>
                <div className="step-data-content">
                  <h3>Filter</h3>
                  <p>
                    Our Team will do the diligent legwork of finding the best
                    Adult Family Home for your needs, budget, and location.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="steps-data"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="step1 d-flex">
                <div className="step-data-img">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721019799/chat-gif-150x150_dcrlze.gif"
                    alt=""
                  />
                </div>
                <div className="step-data-content">
                  <h3>Connect</h3>
                  <p>Tour and Compare Homes According to Your Needs.</p>
                </div>
              </div>
            </div>
            <div
              className="steps-data"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="step1 d-flex">
                <div className="step-data-img">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721298564/move-in-gif-150x150_ifxyni.gif"
                    alt=""
                  />
                </div>
                <div className="step-data-content">
                  <h3>Move-In</h3>
                  <p>
                    Move your loved one into an Adult Family Home that is just
                    right for them!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-12 steps-heading"
            // data-aos="zoom-in-up"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            <div className="stepsimg mt-4">
              <div className="stepsimg1">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1719917466/stepsimg1_u4pttg.jpg"
                  alt=""
                />
              </div>
              <div className="stepsimg2 ">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1719917467/stepsimg2_edejqu.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
