import React from "react";
import { Outlet } from "react-router-dom";
import OwnerSideNav from "../Components/OwnerSideNav/OwnerSideNav";
import OwnerDashBoardNavBar from "../Components/OwnerDashBoardNavBar/OwnerDashBoardNavBar";

const OwnerWebsiteLayout = () => {
  return (
    <div className="">
      <OwnerDashBoardNavBar />
      <div
        className="row"
        style={{
          padding: "0px",
          height: "100vh",
          width: "100%",
          position: "relative",
        }}
      >
        {/* Dashboard Navbar */}

        {/* marginLeft: "5rem",
              padding: "10px 10px 10px 30px",
              // overflow: "auto",
              // height: "80vh",
            }}
          >
            <Outlet />
          </div> */}
        {/* Side Navigation */}
        <div className="col-lg-1">
          <OwnerSideNav />
        </div>

        {/* Main Content Area */}
        <div
          className="col-lg-11 col-md-12 col-12"
          style={{
            backgroundColor: "#8080800d",
            padding: "5px 10px 10px 29px",
            overflowY: "auto", // Vertical scroll only
            height: "100%", // Take full viewport height
            scrollbarWidth: "none",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default OwnerWebsiteLayout;
