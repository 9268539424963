import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../UserSideNav/UserSideNav.css";
import {
  RiHome4Line,
  RiCustomerServiceLine,
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiDashboardFill,
  RiMenu2Fill,
} from "react-icons/ri";
import { MdHelpOutline } from "react-icons/md";

const UserSideNav = () => {
  const [dropdowntoggle1, setDropdownToggle1] = useState(false);
  const [dropdowntoggle2, setDropdownToggle2] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleDropdown1 = () => {
    setDropdownToggle1(!dropdowntoggle1);
    setDropdownToggle2(false);
  };

  const toggleDropdown2 = () => {
    setDropdownToggle2(!dropdowntoggle2);
    setDropdownToggle1(false);
  };
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="user-side-main" ref={sidebarRef}>
      <div className="user-menu">
        <RiMenu2Fill
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
            setDropdownToggle1(false);
            setDropdownToggle2(false);
          }}
        />
      </div>

      {/* <div
        className={`d-flex flex-column side-menu-bar ${
          isSidebarOpen ? "open" : "closed"
        }`}
      > */}

      <div className="d-flex flex-column user-side-menu-bar ">
        <NavLink to="/user/profile" className="user-sidemenubar-dec">
          <div className="user-sidemenubar-one">
            <RiDashboardFill className="user-menu-icon" />
            {isSidebarOpen && <span>Profile</span>}
          </div>
        </NavLink>

        <div
          className={`dropdown d-flex justify-content-between user-sidemenubar-one user-sidemenubar-dec ${
            dropdowntoggle1 ? "active" : ""
          }`}
          onClick={() => {
            toggleDropdown1();
            setIsSidebarOpen(true);
          }}
          aria-expanded={dropdowntoggle1}
        >
          <span>
            <RiHome4Line className="user-menu-icon me-2" />
            {isSidebarOpen && <span>My Homes</span>}
          </span>
          <span>
            {isSidebarOpen ? (
              <span>
                {dropdowntoggle1 ? (
                  <RiArrowDownSLine className=" user-arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" user-arrow-icon" />
                )}
              </span>
            ) : (
              <RiArrowLeftSLine className=" user-arrow-icon" />
            )}
          </span>
        </div>
        <span>
          {isSidebarOpen ? (
            <>
              {dropdowntoggle1 ? (
                <span>
                  <div className="user-sidemenubar-one-drop">
                    <NavLink
                      to="/user/home-owner"
                      className="user-dropdown-item mt-2"
                    >
                      List Your Home
                    </NavLink>
                  </div>
                  {/* <div className="user-sidemenubar-one-drop">
                    <NavLink to="#" className="user-dropdown-item mt-2">
                      List New Homes
                    </NavLink>
                  </div> */}
                </span>
              ) : (
                " "
              )}
            </>
          ) : (
            " "
          )}
        </span>

        <NavLink to="/user/provider" className="user-sidemenubar-dec">
          <div className="user-sidemenubar-one">
            <RiCustomerServiceLine className="user-menu-icon" />
            {isSidebarOpen && <span>Provider</span>}
          </div>
        </NavLink>

        <div
          className={`dropdown d-flex justify-content-between user-sidemenubar-one user-sidemenubar-dec ${
            dropdowntoggle2 ? "active" : ""
          }`}
          onClick={() => {
            toggleDropdown2();
            setIsSidebarOpen(true);
          }}
          aria-expanded={dropdowntoggle2}
        >
          <span>
            <MdHelpOutline className="user-menu-icon me-2" />
            {isSidebarOpen && <span>Help</span>}
          </span>
          <span>
            {isSidebarOpen ? (
              <span>
                {dropdowntoggle2 ? (
                  <RiArrowDownSLine className=" user-arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" user-arrow-icon" />
                )}
              </span>
            ) : (
              <RiArrowLeftSLine className=" user-arrow-icon" />
            )}
          </span>
        </div>

        <span>
          {isSidebarOpen ? (
            <>
              {dropdowntoggle2 ? (
                <span>
                  <div className="user-sidemenubar-one-drop">
                    <NavLink to="/user/faq" className="user-dropdown-item mt-2">
                      FAQs
                    </NavLink>
                  </div>
                  {/* <div className="user-side-menu-bar-one-drop">
                    <NavLink to="#" className="user-dropdown-item mt-2">
                      Contact
                    </NavLink>
                  </div> */}
                </span>
              ) : (
                " "
              )}
            </>
          ) : (
            " "
          )}
        </span>
      </div>
    </div>
  );
};

export default UserSideNav;
