import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import './CommonModal.css'

const CommonModal = (props) => {
  const navigate = useNavigate();

  // Ensure that props.message is defined before destructuring
  const { msg = '', urlmodaldata = '' } = props.message || {};

  const closeModal = () => {
    if (urlmodaldata !== '') {
      navigate(urlmodaldata);
    }
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=''
        //   style={{width:'25%'}}
      >
        <Modal.Header closeButton className='modalfooter'></Modal.Header>
        <Modal.Body className='succ-modal__text'>
          <div className="succ-icon">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706091643/icons8-success_e6wlei.gif"
              alt="success" className="succ-icon-image"
            />
          </div>
          <p>{msg}</p>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
          {/* <Button onClick={closeModal} className="commanmodalbtn" style={{ background: "#108e58", border: "none", width: "100%" }}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CommonModal;
