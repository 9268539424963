import React, { useState } from "react";
import "./Progressbarcomponent.css"; // Assuming you're using an external CSS file

const Progressbarcomponent = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [1, 2, 3, 4];
  const totalSteps = steps.length;

  // Update the current step and state
  const updateSteps = (action) => {
    if (action === "next" && currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else if (action === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <main className="main-wrapper">
      <div className="steps-wrapper">
        <div className="steps">
          {steps.map((step, index) => (
            <span
              key={index}
              className={`step ${index + 1 <= currentStep ? "active" : ""}`}
            >
              {step}
            </span>
          ))}
          <div className="progress-bar">
            <span
              className="progress"
              style={{
                width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
              }}
            ></span>
          </div>
        </div>
        <div className="buttons">
          <button
            className="btn btn-prev"
            onClick={() => updateSteps("prev")}
            disabled={currentStep === 1}
          >
            Previous
          </button>
          <button
            className="btn btn-next"
            onClick={() => updateSteps("next")}
            disabled={currentStep === totalSteps}
          >
            Next
          </button>
        </div>
      </div>
    </main>
  );
};

export default Progressbarcomponent;
