import React, { useEffect, useState } from "react";
// import UserDashborad from "./UserDashboard/UserDashborad";
// import DashBoardNavBar from "./DashBoardNavBar/DashBoardNavBar";
import { Outlet } from "react-router-dom";
import UserSideNav from "../Components/UserSideNav/UserSideNav";
import UserDashBoardNavBar from "../Components/UserDashBoardNavBar/UserDashBoardNav";

const UserWebsiteLayout = () => {
  // const [matches, setMatches] = useState(
  //   window.matchMedia("(max-width:550px)").matches
  // );
  // useEffect(() => {
  //   window.matchMedia("(max-width:550px)").addEventListener("change", (e) => {
  //     setMatches(e.matches);
  //     window.location.reload();
  //   });
  // }, [matches]);
  return (
    <div>
      {/* {matches ? (
        <div className="desktopViewNotSupport">
          <div className="text-white">
            <h1 className="px-3 text-center" style={{ color: "#000" }}>
              DashBorad is only supported in Desktop View. Please adjust your
              screen resolution
            </h1>
          </div>
        </div>
      ) : ( */}
      <div>
        <UserDashBoardNavBar />
        <div
          className="row"
          style={{
            padding: "0px",
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          {/* <DashBoardNavBar /> */}
          <div className="col-lg-1 ">
            {/* <UserDashborad /> */}
            <UserSideNav />
          </div>
          <div
            className="col-lg-11 col-md-12 col-12"
            style={{
              backgroundColor: "#8080800d",
              padding: "5px 10px 10px 29px",
              overflowY: "auto",
              height: "100%",
              scrollbarWidth: "none",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWebsiteLayout;
