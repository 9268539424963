import React, { useEffect, useState } from "react";
import "./AllAccounts.css";
import * as API from "../../../Components/util/url";
import SearchDashboard from "../../../Common/searchdashboard/SearchDashboard";
import PaginationWrapper from "../../../Common/Pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";

const AllAccounts = () => {
  const [allaccount, setAllAccount] = useState([]);
  const [singleHead, setSingleHead] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [selectedAccounts, setSelectedAccounts] = useState([]); // To store selected accounts
  const [selectAll, setSelectAll] = useState(false);
  const [size, setSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  

  const getSerialNumber = (page, size, index) => {
    return (page - 1) * size + (index + 1);
  };

  // // Delete API
  const deleteAll = async () => {
    try {
      const idsToDelete = selectedAccounts.map(account => account.id);
      console.log(idsToDelete, 'IDs to delete');

      const deleteresp = await API.DeleteAccountById({
        ids: idsToDelete,
      });
      console.log(deleteresp, 'delete response');

      // Refresh the list after deletion
      fetchAllAccounts(); // Change from AllAccounts() to fetchAllAccounts()
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedAccounts(allaccount); // Select all accounts
    } else {
      setSelectedAccounts([]); // Deselect all
    }
  };

  const handleAccountSelection = (account) => {
    if (selectedAccounts.includes(account)) {
      setSelectedAccounts(selectedAccounts.filter(acc => acc !== account)); // Deselect
    } else {
      setSelectedAccounts([...selectedAccounts, account]); // Select
    }
  };

  // API integration
  const fetchAllAccounts = async (username = "") => {
    try {
      let response = await API.GetAllAccounts({
        username,
        pageNumber: activePage,
        pageSize: size,
      });
      console.log(response.data.status);
      console.log(response.data.accounts.length);
      if(response.data.status===false)
        {
          toast.error('Accounts not found');
        }
      if (response.data.status && response.data.accounts.length > 0) {
        setSize(response.data.accounts.length);
        setDataCount(response.data.totalPages / size + 1);
        setPageCount(Math.ceil(response.data.totalPages / size));
      }
     
      setAllAccount(response.data.accounts);
      singleHeadHandler(response.data.accounts[0]);
      console.log(response, "all accounts");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timerOut = setTimeout(() => {
      fetchAllAccounts(singleHead);
    }, 800);
    return () => clearTimeout(timerOut);
  }, [size, activePage]);

  // Handle search
  const singleHeadHandler = (data) => {
    setSingleHead([data]);
  };

  const HandleSearch = (searchData) => {
    console.log(searchData);
    let obj = "";
    singleHead?.forEach((data) => {
      obj += ` ${searchData}, `;
    });
    obj = obj.trim().slice(0, -1);
    console.log("Search String:", obj);
    fetchAllAccounts(obj);
    
  };

  return (
    <div className="mt-3 m-auto container">
      <h1 className="table-heading">All Accounts</h1>
      <div className="container seachbar m-auto">
        <SearchDashboard
          style={{ marginBottom: "20px" }}
          onSearch={HandleSearch}
          onDelete={deleteAll} 
        />
      </div>

      <div className="table_responsive">
        <table className="tablemain">
          <thead className="tableheader">
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="table-checkbox"
                  id="table-checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th>Sr.No</th>
              <th>UID</th>
              <th>User Name</th>
              <th>User Email</th>
              <th>Type</th>
              <th>Contact.no</th>
              <th>Listing</th>
              <th>Verified</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {allaccount &&
              allaccount.map((account, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      name="account-checkbox"
                      checked={selectedAccounts.includes(account)}
                      onChange={() => handleAccountSelection(account)}
                    />
                  </td>
                  <td>{getSerialNumber(activePage, size, index)}</td>
                  <td>{account.id}</td>
                  <td>{account.username}</td>
                  <td>{account.email}</td>
                  <td>{account.role}</td>
                  <td>{account.contactNumber}</td>
                  <td>{account.listing}</td>
                  <td>{account.isVerified}</td>
                  <td>{account.creationDate}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaginationWrapper
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  );
};

export default AllAccounts;
