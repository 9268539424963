import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import AnalyticsGraph from "../../../Common/AnalyticsGraph/AnalyticsGraph";
import * as API from "../../../Components/util/url"
const AdminDashboard = () => {
 const [getdata,setGetData]=useState({})
  const cardData = [
    {
      id: 1,
      title: "Total Listing",
      count: getdata.totalListings,
      icon: "fas fa-list",
      progress: 100,
    },
  
    
    {
      id: 2,
      title: "No. of Cities",
      count: getdata.totalCities,
      icon: "fas fa-city",
      progress: 100,
    },
    {
      id: 3,
      title: "Total Users",
      count: getdata.totalUsers,
      icon: "fas fa-user",
      progress: 100,
    },
  
  ];
 
  // api intration

  const GetDashboard=async()=>{
    try {
      let response=await API.GetDashboard();

      setGetData(response.data)
      console.log(response.data,"getdata");
      
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(()=>{
    GetDashboard();
  },[])


  return (
    <div>
      <h1 className="mt-5">Welcome!</h1>
      <div className="container">
        <div className="row">
          {cardData.map((card) => (
            <div className="col-lg-4 col-md-4 col-12" key={card.id}>
              <div className="main-admin-div">
                <div className="card-box">
                  <div className="row">
                    <div className="col-3">
                      <div className="avatar-sm bg-success rounded">
                        <i className={`${card.icon} avatar-title font-22 text-white`} />
                      </div>
                    </div>
                    <div className="col-9 count">
                      <div className="text-right">
                        <h3 className="text-dark my-1">
                          <span data-plugin="counterup">{card.count}</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <p className="text-truncate mt-1 text-center text-dark">{card.title}</p>
                  <div className="mt-1">
                    <div className="progress progress-sm m-0">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        aria-valuenow={card.progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: `${card.progress}%` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-8">
          {/* <AnalyticsGraph/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
