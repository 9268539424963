import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import "./WishListCards.css";
import Badge from "react-bootstrap/Badge";
import * as API from "../../Components/util/url";
import { removeFromWishlist } from "../../Components/util/store/wishlistSlice";
import { useLocation } from "react-router-dom";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";

const ReadMoreLess = ({ text, limit = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  return (
    <div className="details">
      <span>{isExpanded ? text : `${text.slice(0, limit)}...`}</span>
      <span onClick={() => setIsExpanded(!isExpanded)}>
        <span className="read"> {isExpanded ? "Read less" : "Read more"}</span>
      </span>
    </div>
  );
};

const WishListCards = () => {
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const dispatch = useDispatch();

  const [wishlist, setWishlist] = useState([]);

  const handleRemoveFromWishlist = (stayId) => {
    // Dispatch the Redux action to remove the item from the store
    dispatch(removeFromWishlist({ id: stayId }));

    // Optionally, call an API to remove it from the backend
    wishlistapi(stayId);

    // Update the local wishlist state to remove the clicked item
    setWishlist((prevWishlist) =>
      prevWishlist.filter((item) => item.stayId !== stayId)
    );
  };

  const wishlistHandler = async () => {
    try {
      let response = await API.getWishlist();
      setWishlist(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    wishlistHandler();
  }, []);

  // Remove item from API
  const wishlistapi = async (stayId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.AddWishList({ stayId }, token);
      console.log("API response:", response);
    } catch (error) {
      console.error("Error in wishlistapi:", error);
    }
  };

  return (
    <>
    <CommonNavbar style={{ backgroundColor: "#2A342C" }} />
       <div className="main-cart">
      <div className="cart">
        <div className="cart-items">
          <div className="text-center">
            <h2 className="cart-item-heading mt-3">WishList</h2>
          </div>
          <div className="cart-items-title card-heading">
            <p>Items</p>
            <p>Info</p>
            <p>Remove</p>
          </div>
          {/* <hr /> */}
          {Array.isArray(wishlist) && wishlist.length > 0 ? (
            wishlist.map((item, index) => (
              <div key={index}>
                <div className="cart-items-title cart-items-item">
                  <div>
                    <img src={item.imagePath} alt={item.pgName} />
                  </div>
                  <div className="info">
                    <Badge bg="success" className="me-2">
                      {item.rating}
                    </Badge>
                    <span>
                      Review Score :
                      <span className="review-score">
                        {" "}
                        {item.score} Reviews
                      </span>
                    </span>
                    <div className="icons">
                      <i className="fa-solid fa-wifi me-4"></i>
                      <i className="fa-solid fa-wheelchair me-4"></i>
                      <i className="fa-solid fa-truck-medical me-4"></i>
                    </div>
                    <ReadMoreLess text={item.description} limit={110} />
                    <div>
                      <button className="btn-more btn">See More Details</button>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn-remove"
                      onClick={() => handleRemoveFromWishlist(item.stayId)}
                    >
                      <i
                        class="fa-solid fa-heart"
                        style={{ color: "#61ce70" }}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="cart-items-item">No items in wishlist</p>
          )}
        </div>
      </div>
    </div>
    <Footer/>
    </>
 
  );
};

export default WishListCards;
