import React, { useEffect, useState } from "react";
import "./OwnerDashboardPage.css";
import { Link } from "react-router-dom";
import * as API from "../../../Components/util/url"

const OwnerDashboardPage = () => {
const [getdashboard,setGetDashboard]=useState([]);
  // api intration

  const GetDashboard=async()=>{
    try {
      let response=await API.getDashBoardowner();
      console.log(response);
      setGetDashboard(response.data)
      
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(()=>{
    GetDashboard();
  },[])
  return (
    <>
      <div>
        {" "}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="home-listed mb-4 mt-4">
                    <span>
                      <i className="fa-solid fa-house-user"></i>No. of Homes
                      Listed
                    </span>
                    <p>{getdashboard.totalListings}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <div className="total-leads mb-4 mt-4">
                    <span>
                      <i className="fa-solid fa-user-group"></i>Total No. of
                      Leads
                    </span>
                    {getdashboard.totalLeads===null ?
                    <p>0</p>:<p>{getdashboard.totalLeads}</p>}
                    
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 ">
                  <div className="total-views mb-4 mt-4">
                    <span>
                      <i className="fa-solid fa-eye"></i>Total No. of Views
                    </span>
                    <p>03</p>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="list-any-homes mb-4">
                    <p>You can list another home</p>
                    <Link to="/owner/add-new-home" className="btn-list-home">
                      List New Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="dashboard-blog">
                <h2>Recent Blog</h2>
                {/* <div className="recent-blog">
                  <img
                    src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg"
                    alt=""
                  />
                  <h3>Technology for the Aged immensely for the elderly</h3>
                  <p>
                    We live in a technology-laden world. It is not a startling
                    fact that are overly dependent on technology . Imagine a day
                    witho
                  </p>
                  <Link to="" className="btn-recent-blog">
                    View More
                  </Link>
                </div>
                <div className="recent-blog mt-4">
                  <img
                    src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg"
                    alt=""
                  />
                  <h3>Technology for the Aged immensely for the elderly</h3>
                  <p>
                    We live in a technology-laden world. It is not a startling
                    fact that are overly dependent on technology . Imagine a day
                    witho
                  </p>
                  <Link to="" className="btn-recent-blog">
                    View More
                  </Link>
                </div> */}
                <div className="row">
                  <div className="col-lg-4 col-md-6 mt-4">
                    <div className="recent-blog">
                      <img
                        src="https://res.cloudinary.com/ornage/image/upload/v1543564586/afh/blog/images/wqdc9lhhrgjjshen1wwz.jpg"
                        alt=""
                      />
                      <h3>The Benefits of an Adult Family Home Registry</h3>
                      <p>
                      Here are the benefits of adult family homes registry. Our adult family home registry allows for quick and easy access to adult family homes based on: location, senior care services, room types,and activities
                      </p>
                      <Link to="" className="btn-recent-blog">
                        View More
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-4">
                    <div className="recent-blog ">
                      <img
                        src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1566025318/afh/blog/images/bipycluzox0bwyqc2oiq.jpg"
                        alt=""
                      />
                      <h3>Things You Need to Know About Adult Family Homes</h3>
                      <p>
                      The Adult Family Home is a residential home designed to provide personal care services to individuals requiring personal assistance.
                      </p>
                      <Link to="" className="btn-recent-blog">
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDashboardPage;
