// import React, { useState } from "react";
// import "./OwnerDashBoardNavBar.css";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import { NavLink } from "react-router-dom";

// const OwnerDashBoardNavBar = () => {
//   return (
//     <>
//       <div className="dash-navbar">
//         <div>
//           <NavLink to="/">
//             <img
//               src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
//               alt="dashboard-logo"
//               className="company-logo"
//             />
//           </NavLink>
//         </div>
//         <div className="profile">
//           <div>
//             <i className="fa-regular fa-bell dashboard-bell"></i>
//           </div>

//           <div className="profile">
//             <div>
//               <i class="fa-solid fa-circle-user dashboard-userprofile"></i>
//             </div>

//             <NavDropdown id="navbarScrollingDropdown" className="drop-down">
//               <NavDropdown.Item href="#action3">Home</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action4">Logout</NavDropdown.Item>
//             </NavDropdown>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default OwnerDashBoardNavBar;

import React, { useState } from "react";
import "./AdminDashBoardNavBar.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RxCross2 } from "react-icons/rx";
import {
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiDashboardFill,
  RiMenu2Fill,
} from "react-icons/ri";
import { IoMdContact } from "react-icons/io";
import { FaList, FaLayerGroup, FaMobileAlt, FaRocket } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";

const AdminDashBoardNavBar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dropdowntoggle1, setDropdownToggle1] = useState(false);
  const [dropdowntoggle2, setDropdownToggle2] = useState(false);

  const toggleDropdown1 = () => {
    setDropdownToggle1(!dropdowntoggle1);
    setDropdownToggle2(false);
  };

  const toggleDropdown2 = () => {
    setDropdownToggle2(!dropdowntoggle2);
    setDropdownToggle1(false);
  };
  const logoutHandler = () => {
    // Clear login state
    // setIsLoggedIn(false);
    // setUserName("");

    // Remove login status from localStorage
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userName");

    navigate("/"); // Redirect to homepage after logout
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="dash-navbar">
        <div>
          <NavLink to="/">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
              alt="dashboard-logo"
              className="company-logo"
            />
          </NavLink>
        </div>
        <div className="profile admin-user-profile">
          {/* <div>
            <i
              className="fa-regular fa-bell dashboard-bell"
              onClick={handleShow}
            ></i>
          </div> */}

          <div className="profile ">
            <div>
              <i className="fa-solid fa-circle-user dashboard-userprofile"></i>
            </div>

            <NavDropdown
              id="navbarScrollingDropdown"
              className="drop-down-arrow"
            >
              <NavDropdown.Item
                href="/"
                className="navbar-dropdown-home"
              >
                Home
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                // href="#action4"
                className="navbar-dropdown-home"
                onClick={logoutHandler}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <div onClick={handleShow} className="d-lg-none ">
          <RiMenu2Fill className=" offcanvas-admin-menu" />
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="offcanvas-admin-menu custom-offcanvas"
      >
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="profile admin-userprofile">
              <div>
                <i
                  className="fa-regular fa-bell dashboard-bell"
                  onClick={handleShow}
                ></i>
              </div>

              <div className="profile">
                <div>
                  <i className="fa-solid fa-circle-user dashboard-userprofile"></i>
                </div>

                <NavDropdown id="navbarScrollingDropdown" className="drop-down">
                  <NavDropdown.Item href="#action3">Home</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action4">Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body
          style={{
            scrollbarWidth: "none",
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="profile admin-userprofile">
              <div>
                <i
                  className="fa-regular fa-bell dashboard-bell"
                  onClick={handleShow}
                ></i>
              </div>

              <div className="profile">
                <div>
                  <i className="fa-solid fa-circle-user dashboard-userprofile"></i>
                </div>

                <NavDropdown
                  id="navbarScrollingDropdown"
                  className="drop-down-arrow"
                >
                  <NavDropdown.Item
                    href="#action3"
                    className="navbar-dropdown-home"
                  >
                    Home
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="#action4"
                    className="navbar-dropdown-home"
                    onClick={logoutHandler}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>

            <div>
              <div className="cross-close" onClick={handleClose}>
                <RxCross2 />
              </div>{" "}
            </div>
          </div>
          <hr />

          <NavLink to="/supernova" className="admin-side-menubar-dec">
            <div className="admin-side-menubar-one">
              <RiDashboardFill className="admin-menu-icon" />
              <span>Dashboard</span>
            </div>
          </NavLink>
          <NavLink
            to="/supernova/contact-us"
            className="admin-side-menubar-dec"
          >
            <div className="admin-side-menubar-one">
              <IoMdContact className="admin-menu-icon" />
              <span>Contact Us</span>
            </div>
          </NavLink>
          <NavLink
            to="/supernova/all-listing"
            className="admin-side-menubar-dec"
          >
            <div className="admin-side-menubar-one">
              <FaList className="admin-menu-icon" />
              <span>All Listing</span>
            </div>
          </NavLink>

          <NavLink
            to="/supernova/all-accounts"
            className="admin-side-menubar-dec"
          >
            <div className="admin-side-menubar-one">
              <MdAccountBalance className="admin-menu-icon" />
              <span> All Accounts</span>
            </div>
          </NavLink>

          <div
            className={`dropdown d-flex justify-content-between admin-side-menubar-one admin-side-menubar-dec ${
              dropdowntoggle1 ? "active" : ""
            }`}
            onClick={() => {
              toggleDropdown1();
            }}
            aria-expanded={dropdowntoggle1}
          >
            <span>
              <FaLayerGroup className="admin-menu-icon me-2" />
              <span>Leads</span>
            </span>
            <span>
              <span>
                {dropdowntoggle1 ? (
                  <RiArrowDownSLine className=" admin-arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" admin-arrow-icon" />
                )}
              </span>
            </span>
          </div>
          <span>
            <>
              {dropdowntoggle1 ? (
                <span>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/send-leads"
                      className="admin-dropdown-item mt-2"
                    >
                      Send your Leads
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/all-leads"
                      className="admin-dropdown-item mt-2"
                    >
                      All Leads
                    </NavLink>
                  </div>
                </span>
              ) : (
                " "
              )}
            </>
          </span>

          {/* <NavLink
            to="/provider-application"
            className="admin-side-menubar-dec"
          >
            <div className="admin-side-menubar-one">
              <FaMobileAlt className="admin-menu-icon" />
              <span>Provider Aplications</span>
            </div>
          </NavLink> */}

          <div
            className={`dropdown d-flex justify-content-between admin-side-menubar-one admin-side-menubar-dec ${
              dropdowntoggle2 ? "active" : ""
            }`}
            onClick={() => {
              toggleDropdown2();
            }}
            aria-expanded={dropdowntoggle2}
          >
            <span>
              <FaRocket className="admin-menu-icon me-2" />
              <span>SEO</span>
            </span>
            <span>
              <span>
                {dropdowntoggle2 ? (
                  <RiArrowDownSLine className=" admin-arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" admin-arrow-icon" />
                )}
              </span>
            </span>
          </div>

          <span>
            <>
              {dropdowntoggle2 ? (
                <span>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/static-page"
                      className="admin-dropdown-item mt-2"
                    >
                      Static Page
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/city-static-page"
                      className="admin-dropdown-item mt-2"
                    >
                      City Static Page
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink to="/sitemap" className="admin-dropdown-item mt-2">
                      Sitemap
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/cloudinary"
                      className="admin-dropdown-item mt-2"
                    >
                      Cloudinary
                    </NavLink>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink to="/blog" className="admin-dropdown-item mt-2">
                        Blog
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink to="/event" className="admin-dropdown-item mt-2">
                        Event
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink to="/video" className="admin-dropdown-item mt-2">
                        Video
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink
                        to="/upload-dashboard-videos"
                        className="admin-dropdown-item mt-2"
                      >
                        Upload DashBoard Videos
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink
                        to="/gallery"
                        className="admin-dropdown-item mt-2"
                      >
                        Gallery
                      </NavLink>
                    </div>
                  </div>
                </span>
              ) : (
                " "
              )}
            </>
          </span>

          {/* <NavLink to="/supernova" className="offcanvas-admin-dropdown-items">
            Dashboard
          </NavLink>
          <NavLink
            to="/supernova/contact-us"
            className="offcanvas-admin-dropdown-items"
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/supernova/all-listing"
            className="offcanvas-admin-dropdown-items"
          >
            All Listing
          </NavLink>
          <NavLink
            to="/supernova/all-accounts"
            className="offcanvas-admin-dropdown-items"
          >
            All Accounts
          </NavLink>
          <NavDropdown
            title="Leads"
            id="navbarScrollingDropdown"
            className="offcanvas-admin-dropdown-items "
          >
            <NavDropdown.Item as={NavLink} to="/send-leads">
              Send your Leads
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/all-leads">
              All Leads
            </NavDropdown.Item>
          </NavDropdown>
          <NavLink
            to="/provider-application"
            className="offcanvas-admin-dropdown-items"
          >
            Provider Applications
          </NavLink>
          <NavDropdown
            title="SEO"
            id="navbarScrollingDropdown"
            className="offcanvas-admin-dropdown-items offcanvas-custom-dropdown"
          >
            <NavDropdown.Item as={NavLink} to="/static-page">
              Static Page
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/city-static-page">
              City Static Page
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/sitemap">
              Sitemap
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/cloudinary">
              Cloudinary
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/blog">
              Blog
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/event">
              Event
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/video">
              Video
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/upload-dashboard-videos">
              Upload Dashboard Videos
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/gallery">
              Gallery
            </NavDropdown.Item>
          </NavDropdown> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdminDashBoardNavBar;
