import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../OwnerSideNav/OwnerSideNav.css";
import {
  RiHome4Line,
  RiCustomerServiceLine,
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiDashboardFill,
  RiMenu2Fill,
} from "react-icons/ri";
import { MdHelpOutline } from "react-icons/md";

const OwnerSideNav = () => {
  const [dropdowntoggle1, setDropdownToggle1] = useState(false);
  const [dropdowntoggle2, setDropdownToggle2] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleDropdown1 = () => {
    setDropdownToggle1(!dropdowntoggle1);
    setDropdownToggle2(false);
  };

  const toggleDropdown2 = () => {
    setDropdownToggle2(!dropdowntoggle2);
    setDropdownToggle1(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="owner-side-main" ref={sidebarRef}>
      <div className="menu">
        <RiMenu2Fill
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
            setDropdownToggle1(false);
            setDropdownToggle2(false);
          }}
        />
      </div>

      {/* <div
        className={`d-flex flex-column side-menu-bar ${
          isSidebarOpen ? "open" : "closed"
        }`}
      > */}

      <div className="d-flex flex-column side-menu-bar ">
        <NavLink to="/owner/dashboard" className="side-menu-bar-dec">
          <div className="side-menu-bar-one">
            <RiDashboardFill className="menu-icon" />
            {isSidebarOpen && <span>Dashboard</span>}
          </div>
        </NavLink>

        <div
          className={`dropdown d-flex justify-content-between side-menu-bar-one side-menu-bar-dec ${
            dropdowntoggle1 ? "active" : ""
          }`}
          onClick={() => {
            toggleDropdown1();
            setIsSidebarOpen(true);
          }}
          aria-expanded={dropdowntoggle1}
        >
          <span>
            <RiHome4Line className="menu-icon me-2" />
            {isSidebarOpen && <span>My Homes</span>}
          </span>
          <span>
            {isSidebarOpen ? (
              <span>
                {dropdowntoggle1 ? (
                  <RiArrowDownSLine className=" arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" arrow-icon" />
                )}
              </span>
            ) : (
              <RiArrowLeftSLine className=" arrow-icon" />
            )}
          </span>
        </div>
        <span>
          {isSidebarOpen ? (
            <>
              {dropdowntoggle1 ? (
                <span>
                  <div className="side-menu-bar-one-drop">
                    <NavLink
                      to="/owner/listed-homes"
                      className="owner-dropdown-item mt-2"
                    >
                      My Listed Homes
                    </NavLink>
                  </div>
                  <div className="side-menu-bar-one-drop">
                    <NavLink
                      to="/owner/add-new-home"
                      className="owner-dropdown-item mt-2"
                    >
                      List New Homes
                    </NavLink>
                  </div>
                </span>
              ) : (
                " "
              )}
            </>
          ) : (
            " "
          )}
        </span>

        <NavLink to="/owner/afh-services" className="side-menu-bar-dec">
          <div className="side-menu-bar-one">
            <RiCustomerServiceLine className="menu-icon" />
            {isSidebarOpen && <span>AFH Services</span>}
          </div>
        </NavLink>

        <div
          className={`dropdown d-flex justify-content-between side-menu-bar-one side-menu-bar-dec ${
            dropdowntoggle2 ? "active" : ""
          }`}
          onClick={() => {
            toggleDropdown2();
            setIsSidebarOpen(true);
          }}
          aria-expanded={dropdowntoggle2}
        >
          <span>
            <MdHelpOutline className="menu-icon me-2" />
            {isSidebarOpen && <span>Help</span>}
          </span>
          <span>
            {isSidebarOpen ? (
              <span>
                {dropdowntoggle2 ? (
                  <RiArrowDownSLine className=" arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" arrow-icon" />
                )}
              </span>
            ) : (
              <RiArrowLeftSLine className=" arrow-icon" />
            )}
          </span>
        </div>

        <span>
          {isSidebarOpen ? (
            <>
              {dropdowntoggle2 ? (
                <span>
                  <div className="side-menu-bar-one-drop">
                    <NavLink
                      to="/owner/faq"
                      className="owner-dropdown-item mt-2"
                    >
                      FAQs
                    </NavLink>
                  </div>
                  {/* <div className="side-menu-bar-one-drop">
                    <NavLink to="#" className="owner-dropdown-item mt-2">
                      Contact
                    </NavLink>
                  </div> */}
                </span>
              ) : (
                " "
              )}
            </>
          ) : (
            " "
          )}
        </span>
      </div>
    </div>
  );
};

export default OwnerSideNav;
