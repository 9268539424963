import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminDashBoardNavBar from "../Components/AdminDashBoardNavBar/AdminDashBoardNavBar";
import AdminSideNav from "../Components/AdminSideNav/AdminSideNav";

const WebsiteLayout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("afhnew");

  if (!token) {
    navigate("/signin");
    return null;
  } else {
    return (
      <>
        <div className="CommonComponent">
          <AdminDashBoardNavBar />
          <div className="" style={{ position: "relative" }}>
            <div className="row" style={{ padding: "0px", width: "100%" }}>
              <div className="col-lg-1 ">
                <AdminSideNav />
              </div>
              <div
                className="col-lg-11 col-md-12 col-12 "
                style={{
                  height: "100vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default WebsiteLayout;
