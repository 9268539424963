import React, { useEffect, useState } from "react";
import "./ContactUsPage.css";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import * as Yup from "yup";
import * as API from "../../Components/util/url";
import { Formik, Form, Field, ErrorMessage } from "formik";
import L from "leaflet";
// import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MapComponents from "./MapComponents";
import CommonModal from "../../Common/CommonModal/CommonModal";
import CommonModalFail from "../../Common/CommonModal/CommonModalFail";
import { NavLink } from "react-router-dom";

const ContactUsPage = () => {
  const [data, setData] = useState([]);
  const [verified, setVerified] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid phone number")
      .required("Phone number is required"),
    subject: Yup.string().required("Subject is required"),
    describe: Yup.string().required("This field is required"),
    agreeToTerms: Yup.boolean().oneOf([true], "Must agree to terms"),
  });

  // api integration
  const contactus = async (data) => {
    try {
      const response = await API.ContactUs({
        name: data.name,
        phoneNumber: data.phone,
        email: data.email,
        subject: data.subject,
        description: data.describe,
      });
      console.log(response);
      if (response.data.staus === true) {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "/contact-us",
        });
        setShowSuccessModal(true);
      } else {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "/contact-us",
        });
        setShowFailureModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const MarkerLoc = async () => {
      try {
        const resp = await API.MarkerMapList();
        setData(resp.data.data);
      } catch (error) {
        console.log("Error", error);
      }
    };
    MarkerLoc();
  }, []);
  const phoneNumber = "+1(425)  5772018 ";
  return (
    <>
      <div className="families">
        <CommonNavbar />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <div className="familybanner">
                {" "}
                <h1 className="mt-5" style={{ paddingBottom: "5rem" }}>
                  CONTACT US
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="help-you-section ordertwo">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="contact-section">
                <h2>
                  When You Need Long Term Care Assistance, Let us Help You
                </h2>
              </div>
              <div className="contact-section-info">
                <p>
                  Adult Family Home Solutions is committed to helping the
                  thousands of people searching for care options in their area
                  with an easy way to navigate, contact, and choose long term
                  care in an Adult Family Home setting.
                </p>
                <p>
                  We are senior care providers and Adult Family Homeowners on a
                  mission to revolutionize the way the senior care industry
                  works and understand the difficulty of navigating long term
                  care. .
                </p>
                <p>
                  If you need any help or further assistance in the long term
                  care process, choosing an Adult Family Home, or using our
                  website, we are more than happy to help you. Just fill out
                  this form and one of our senior care experts will get back to
                  you shortly!
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720703692/providerimg2_rd1931.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "500px", width: "100%" }} className="orderone">
        {data.length > 0 ? (
          <MapComponents data={data} />
        ) : (
          <p>Loading data...</p>
        )}
      </div>
      <div className="form-contactUs">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-12 ">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721825123/girlimage500px_o4zs6z.png"
                alt="contact-girl"
                className="contact-girl-img"
              />
            </div>
            <div className="col-lg-7 col-md-7 col-12 mt-5">
              <div className="contact-infonumber">
              <p>Call  Us  At 
                <NavLink to={`tel:${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>  <button className=" phnno-btn">
          Call {phoneNumber}
        </button></NavLink></p>
              <p>Or, Send A Message</p>
              </div>
              
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  subject: "",
                  describe: "",
                  agreeToTerms: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
              
                  contactus(values);
                 
                  resetForm();
                }}
              >
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="contact-form">
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error-message"
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-lg-6 col-md-6 col-12 resfeild">
                      <div className="contact-form">
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <div className="contact-form">
                        <Field
                          type="number"
                          name="phone"
                          id="phone"
                          placeholder="Phone"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <div className="contact-form">
                        <Field
                          as="select"
                          name="subject"
                          id="subject"
                          className="form-control"
                        >
                          <option value="" label="Select Subject" />
                          <option
                            value="senior-placement-specialist"
                            label="Senior Placement Specialist"
                          />
                          <option
                            value="customer-support"
                            label="Customer Support"
                          />
                          <option
                            value="technical-support"
                            label="Technical Support"
                          />
                          <option value="medicaid" label="Medicaid" />
                        </Field>
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 mt-4">
                      <div className="contact-form">
                        <Field
                          as="textarea"
                          id="describe"
                          name="describe"
                          placeholder="Describe Your Senior Care Need Here"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="describe"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 mt-4">
                      <div className="contact-form">
                        <label
                          htmlFor="agreeToTerms"
                          style={{ display: "flex", color: "white" }}
                        >
                          <Field
                            type="checkbox"
                            id="agreeToTerms"
                            name="agreeToTerms"
                            className="mx-2"
                            style={{ width: "14px" }}
                          />
                          I agree to the terms and conditions
                        </label>
                        <ErrorMessage
                          name="agreeToTerms"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 mt-4">
                      <div className="committing-help">
                        <h4>Committed to Helping</h4>
                        <p>
                          Please leave a detailed description of your need and
                          we will get back to you shortly!
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 mt-3 re-captch">
                      <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        onChange={onChange}
                      />

                      <button
                        className="contact-btn mt-3 mb-3"
                        disabled={!verified}
                      >
                        Submit Your Request
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message={message}
      />
      <CommonModalFail
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        message={message}
      />
      <Footer />
    </>
  );
};

export default ContactUsPage;
