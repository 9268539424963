import React from "react";

const Benefits = () => {
  return (
    <div className="section-3">
      <div className="overlay-section3"></div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12 ">
            <div className="section-3text">
              {/* <div className="stars mt-5">
                <span
                  className="fa fa-star checked"
                  style={{ paddingRight: "10px" }}
                >
                  {" "}
                </span>
                <span>We are here to help!</span>
              </div> */}
              <h1 className="mt-5">Benefits of Our Service</h1>
              <div className="content3-sec">
                <div className="benefit1" data-aos="fade-right">
                  <div className="number">1 </div>
                  <span>
                    Our FREE Senior Placement Specialist is available at all
                    times.
                  </span>
                </div>
                <div className="benefit1" data-aos="fade-right">
                  <div className="number">2</div>
                  <span>
                    We specialize only in Adult Family Home placements and know
                    what to look for and all the questions that should be asked.
                  </span>
                </div>
                <div className="benefit1" data-aos="fade-right">
                  <div className="number">3</div>
                  <span>
                    Our in-depth personalized intake process helps us find the
                    right home that will meet your care needs.
                  </span>
                </div>
                <div className="benefit1" data-aos="fade-right">
                  <div className="number">4</div>
                  <span>
                    Information is always readily available and easy to
                    navigate.
                  </span>
                </div>
                <div className="benefit1" data-aos="fade-right">
                  <div className="number">5</div>
                  <span>
                    We are with you every step of the way and make your needs
                    our priority!
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="sectio-3-background">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1719922877/sect-3-afh_heaqac.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
