import React from "react";
import "./Policies.css";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
const CancellationPolicy = () => {
  return (
    <div>
      <CommonNavbar style={{ backgroundColor: "#10481A" }} />
      <div className="container mt-5 mb-5">
        <div className="row">
            <div className="col-lg-1 col-md-1 col-12"></div>
            <div className="col-lg-10 col-md-10 col-12">
            <div className="policy-heading">
            <h1>Refund and Cancellation Policy</h1>
            <hr className="policy-hr" />
            <p className="policy-para">
              Our focus is complete customer satisfaction. In the event, if you
              are displeased with the services provided, we will refund back the
              money, provided the reasons are valid and proven after
              investigation. Please read the fine prints of each deal before
              buying it, it provides all the details about the services or the
              product you purchase. <br />
              In case of dissatisfaction from our services, clients have the
              liberty to cancel their projects and request a refund from us. Our
              Policy for the cancellation and refund will be as follows:
            </p>

            <h4>Use Of Content</h4>
            <hr className="policy-hr" />

        <p className="policy-para">
        All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations thereof, appearing in this site, except as otherwise noted, are properties either owned, or used under license, by the business and / or its associate entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these terms and conditions or in the site content, is strictly prohibited. <br />
        Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.
        </p>

        <h4>Cancellation Policy</h4>
        <hr className="policy-hr" />
        <div className="policy-para">
         <p className="policy-para">For Cancellations please contact us via contact us link.</p>
         <p className="policy-para">Requests received later than 15 business days prior to the end of the current service period will be treated as cancellation of services for the next service period.</p>

         <h4>Refund Policy</h4>
         <hr className="policy-hr" />
         <p className="policy-para">
         We will try our best to create the suitable design concepts for our clients.
         </p>
        </div>

        <h4>Refund Policy</h4>
        <hr className="policy-hr" />
        <p className="policy-para">We will try our best to create the suitable design concepts for our clients.</p>
        <p className="policy-para">In case any client is not completely satisfied with our service we can provide a refund.</p>
        <p className="policy-para">If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account.</p>


        <h4>Liability</h4>
        <hr className="policy-hr" />
        <p className="policy-para">User agrees that neither Company nor its group companies, directors, officers or employee shall be liable for any direct or/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages, resulting from the use or/and the inability to use the service or/and for cost of procurement of substitute goods or/and services or resulting from any goods or/and data or/and information or/and services purchased or/and obtained or/and messages received or/and transactions entered into through or/and from the service or/and resulting from unauthorized access to or/and alteration of user's transmissions or/and data or/and arising from any other matter relating to the service, including but not limited to, damages for loss of profits or/and use or/and data or other intangible, even if Company has been advised of the possibility of such damages. </p>

        <p className="policy-para">User further agrees that Company's shall not be liable for any damages arising from interruption, suspension or termination of service, including but not limited to direct or/and indirect or/and incidental or/and special consequential or/and exemplary damages, whether such interruption or/and suspension or/and termination was justified or not, negligent or intentional, inadvertent or advertent. </p>

        <p className="policy-para">User agrees that Company shall not be responsible or liable to user, or anyone, for the statements or conduct of any third party of the service. In sum, in no event shall Company's total liability to the User for all damages or/and losses or/and causes of action exceed the amount paid by the User to Company, if any, that is related to the cause of action.</p>

        <h4>Disclaimer of Consequential Damages</h4>
        <hr className="policy-hr" />
        <p className="policy-para">In no event shall Company or any parties, organizations or entities associated with the corporate brand name us or otherwise, mentioned at this Website be liable for any damages whatsoever (including, without limitations, incidental and consequential damages, lost profits, or damage to computer hardware or loss of data information or business interruption) resulting from the use or inability to use the Website and the Website material, whether based on warranty, contract, tort, or any other legal theory, and whether or not, such organization or entities were advised of the possibility of such damages.</p>

          </div>
            </div>
            <div className="col-lg-1 col-md-1 col-12"></div>
            
       
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CancellationPolicy;
