import React from "react";
import "./UserDashboardFaq.css";
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";

const UserDashboardFaq = () => {
  const UserDashboardFaqData = [
    {
      id: 1,
      title: " What is an adult family home?",
      content:
        " An adult family home is a residential home where up to six residents are being cared for.",
    },
    {
      id: 2,
      title: "What is the difference between an Adult Family Home and Assisted Living?",
      content:
        "An Adult Family Home provides more personalized care in a more home-like setting rather than having the feel of a large and impersonal facility.",
    },
    {
      id: 3,
      title: "How is this registry different than referral agencies?",
      content:
        "Unlike referral agencies, which steer you toward the homes of their choosing, we provide you with the contact information of the homes that interest you, and give you the freedom to decide which of the homes you should contact.",
    },
    {
      id: 4,
      title: "How do I know which Adult Family Home will work best for my parent?",
      content:
        "You can directly contact Adult Family Homes in your area and filter them according to the services provided (based on the needs of your parent).",
    },
    {
      id: 5,
      title: "How do Adult Family homes stay accountable to the state?",
      content:
        "The state of Washington conducts yearly surprise inspections to ensure that Adult Family homes are operating at a an optimum level.",
    },
    {
      id: 6,
      title: "What is the next step after I contact some Adult Family Homes?",
      content:
        " After learning a little bit about their home over the phone, you can schedule a tour with a few of the homes you feel most comfortable with.",
    },
    {
      id: 7,
      title: "What should I expect during a home tour?",
      content:
        "During the tour the AFH provider will walk you through the home, show you the available rooms, and explain in detail, how the home will meet the needs of your parent.",
    },
    {
      id: 8,
      title: "Are there services that an Adult Family Home does not provide?",
      content:
        "Adult Family Homes are equipped to provide a wide range of services for the elderly. Most notable is the 24 hour care, with assistance of all activities of every day living. Most homes provide assistance for Dementia and Mental Health. Some homes are RN owned and operated.",
    },
    {
      id: 9,
      title: "How much care should my parent anticipate from an Adult Family Home?",
      content:
        "Adult family Homes provide as much or as little care as the resident requires. Residents that are more independent will be encouraged to use their abilities while those needing assitance will be helped more.",
    },
    {
      id: 10,
      title: "Who takes care of the residents in an Adult Family Home?",
      content:
        "Most Adult Family Homes are either run by the owner (Provider) or they have a resident manager that oversees the house and a care staff that deals with the care and day to day activity of the home.",
    },
    {
      id: 11,
      title: "What does a typical day in an Adult Family Home look like?",
      content:
        "While each home is different, below you can see some general points that appear in a typical day. Residents are assisted with dress and grooming before serving breakfast. Rooms and laundry are cleaned. Activities and exercises are encouraged. Toileting may take place either before or after meals. Lunch is served followed by a time of relaxation or more organized activities and games. In the evening there is dinner and the day begins to wind down by watching one's favorite show before being assisted with the bedtime routine.",
    },
    {
      id: 12,
      title: "How often can I visit my parent in an Adult Family Home?",
      content:
        "Families can visit as much or as little as they please. Family interaction is to encourage and the Adult Family Home coordinates contact between family and resident.",
    },
    {
      id: 13,
      title: "How can I unsubscribe the home?",
      content:
        "For unsubscribe the home, log in your account then click on subscription details and unsubscribe the home by clicking on unsubscribe tab. You need to fill the feedback form after unsubscribing the home.",
    },
  ];

  return (
    <>
      <h1 className="faqheading">Frequently Asked Questions</h1>
      <div className="user-dashboard-faq ">
        <Accordion defaultActiveKey="0">
          <div className="accordion accordionbox">
            {UserDashboardFaqData.map((item) => (
              <Accordion.Item eventKey={item.id}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.content}</Accordion.Body>
              </Accordion.Item>
            ))}
          </div>
        </Accordion>
        <div className="faq-provider">
          <h3>
            <strong>Do you want to became an AFH Provider</strong>
          </h3>
          <NavLink to="/user/provider" className="button-next btn-success">
            Proceed
          </NavLink>
        </div>
        <div className="faq-provider mt-4">
          <h3>
            <strong>Do You Have Any Question</strong>
          </h3>
          <NavLink className="button-next btn-success">Contact Us</NavLink>
        </div>
      </div>
    </>
  );
};

export default UserDashboardFaq;
