import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as API from "../../Components/util/url";
import { useLocation } from 'react-router-dom';
import CommonModal from '../CommonModal/CommonModal';
import './Confirmation.css'
const ConfirmationModal = (props) => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [message, setMessage] = useState({
        msg: "",
        urlmodaldata: "",
      });
    const location=useLocation();
    // const state=location.state.msgId;
    const { contactdetails } = props;
     // delete api
  const DeleteAPI=async(id)=>{
    try {
      const response=await API.AdminContactusDelete(id);
      
      console.log(response);
      if(response.data.status===true){
        setMessage({
          msg:response.data.message,
          urlmodaldata:'',
        });
        setShowSuccessModal(true);
        setTimeout(() => {
            props.onHide();
          }, 3000);
     
    }
    } catch (error) {
      console.log(error);
      
    }
  }
  return (
    <div>
      <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    backdrop="static"
    // centered
    className='modalmaindiv m-auto'
    >
      <Modal.Header className='modalheader'closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p style={{fontSize:'18px',textAlign:'center'}}>
        Are you sure you want to delete this?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className='btn-success '>Cancle</Button>
        <Button
        className='btn-success '
           onClick={() => DeleteAPI(contactdetails?.msgId)}
        >Confirm</Button>
      </Modal.Footer>
    </Modal>
    <CommonModal
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
          message={message}
        />
    </div>
  )
}

export default ConfirmationModal
