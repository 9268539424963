import React, { useEffect, useState } from "react";
import "./OwnerListedHomes.css";
import { Link, NavLink } from "react-router-dom";
import * as API from "../../../Components/util/url";

const OwnerListedHomes = () => {
  const [listhome, setListHome] = useState([]);

  // api integration
  const allListedHome = async () => {
    try {
      let response = await API.getListedhome();
      setListHome(response.data.homes);
      console.log(response.data.homes, "listing homes");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    allListedHome();
  }, []);
  return (
    <div className="container">
      <h2 className="listedhome">All Listed Home</h2>
      <hr className="listedhomehr" />
      <div className="row mt-4 ">
        {listhome &&
          listhome.map((home,id) => (
            <div className="col-lg-4 col-md-6 col-12" key={home.id}>
              <div className="listed-homes mb-4">
                <img src={home.imagePath} alt="listed-homes-img" />
                <div className="reviews">
                  <div className="reviewbtn">
                    {parseFloat(home.averageRatings)}
                  </div>

                  <p className="reviewheading">
                    Review Score <span>{home.totalReviews} Reviews</span>
                  </p>
                  {/* <NavLink
                    to="/addreviwes"
                    state={{
                      stayId: home.stayId,
                      pgName: home.pgName,
                    }}
                    className="addreviewslink"
                  >
                    Add Reviews
                  </NavLink> */}
                  <div  style={{ textAlign: "right" }}>
                    <NavLink to='/owner/listed-homesEdit' className="editicon"
                     state={{ stayId: home.stayId,alldata:home}}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </NavLink>
                  </div>
                </div>
                <span>{home.metaTitle}</span>
                <div>{home.ownerName}</div>
                <div>{home.ownerEmail}</div>
                <p style={{ marginBottom: "5px" }}>{home.address}</p>
                <p>
                  {home.city} <span>{home.zipCode}</span>
                </p>

                <div className="listingmorepage">
                  <NavLink
                    to={`/list-home-LandingPage/${home.stayId}`}
                    className="btn-list-home mt-4"
                    state={{
                      homeDetails: home,
                    }}
                  >
                    View More
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OwnerListedHomes;
