import React from "react";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import Accordion from "react-bootstrap/Accordion";
import "./Families.css";
import "../MediaQuery/MediaQuery.css";

const Families = () => {
  const initialvalue = [
    {
      ques1: " 1. How long have they been operating their adult family home?",
      ques2:
        " 2. What is their education and experience in the senior care industry?",
    },
    {
      ques1: "3. How are family visits handled and when are visiting hours?",
      ques2:
        "4.What kinds of activities are planned and structured during the day?",
    },
    {
      ques1:
        "5. Do they provide medication management? How is medication management handled?",
      ques2:
        " 6. Is some level of nursing care provided, and how often is it available?",
    },
    {
      ques1: "7. Are transportation services provided?",
      ques2: "8. Are bedrooms private or shared?",
    },
    {
      ques1:
        "9. What amenities are within close walking distance - library, shopping, movie theater, etc?",
      ques2:
        "10.Is there an area to enjoy outdoor activities such as walking, sitting or gardening?",
    },
    {
      ques1:
        "11. How many staff are generally on shift, both during the day and night?",
      ques2: "12. What is the cost of care services?",
    },
  ];

  const faqData = [
    {
      id: 1,
      title:
        "What is the difference between an adult family home and retirement community/assisted living?",
      content:
        "An Adult Family Home provides more personalized care in a more home-like, residential setting rather than having the feel of a large or impersonal facility.",
    },
    {
      id: 2,
      title: "What is a Senior Placement Specialist?",
      content:
        "A Senior Placement Specialist assists elderly and/or vulnerable adults in identifying the best senior housing options that will meet their needs. They start off by gathering essential information about the elderly adult (client). Through this process they identify the different needs, interests, preferences, level of assistance and financial budget of the client. Then based on this information, the Senior Placement Specialist will provide and set up tours with the best Adult Family Homes that would be of interest and most suitable.",
    },
    {
      id: 3,
      title:
        "How do adult family homes stay accountable to the state in which they operate?",
      content:
        "Each state conducts yearly surprise inspections to ensure that Adult Family homes are operating at a an optimum level. All records are available on the Department of Social and Health Services in the respective state in which they operate. At adultfamilyhomes.org, we work hard to ensure every listing on our site is vetted, legitimate, and responsive to our families searching for elderly care. Any fraudulent listings are taken down immediately and though we dont own any of the homes listed on our site, we take very serious compliance with the DSHS and our featured partners and preferred members.",
    },
    {
      id: 4,
      title: "What should I expect during a home tour?",
      content:
        "During the tour the AFH provider will walk you through the home, show you the available rooms, and explain in detail, how the home will meet the needs of your loved one.",
    },
    {
      id: 5,
      title: "Are there services that an Adult Family Home does not provide?",
      content:
        " Adult Family Homes are equipped to provide a wide range of services for the elderly. Most notable is the 24 hour care, with assistance of all activities of every day living. Most homes provide assistance for Dementia and Mental Health. Some homes are RN owned and operated. All the services our listed homes offer are available to filter and select for families in the search bar.",
    },
    {
      id: 6,
      title:
        "How much care should my parent anticipate from an Adult Family Home?",
      content:
        " Adult family Homes provide as much or as little care as the resident requires. Residents that are more independent will be encouraged to use their abilities while those needing assistance will be helped more. Usually homes will do an assessment of a new resident before they move in and classify them anywhere between light and heavy care. The light or heavy care will also be a direct input into the range of daily rates families will have to cover.",
    },
    {
      id: 7,
      title: "Who takes care of the residents in an Adult Family Home?",
      content:
        " Most Adult Family Homes are either run by the owner (Provider) or they have a resident manager that oversees the house and a care staff that deals with the care and day to day activity of the home.",
    },
    {
      id: 8,
      title: "What does a typical day in an Adult Family Home look like?",
      content:
        " While each home is different, below you can see some general points that appear in a typical day. Residents are assisted with dress and grooming before serving breakfast. Rooms and laundry are cleaned. Activities and exercises are encouraged. Toileting may take place either before or after meals. Lunch is served followed by a time of relaxation or more organized activities and games. In the evening there is dinner and the day begins to wind down by watching ones favorite show, book, or radio before being assisted with the bedtime routine.",
    },
    {
      id: 9,
      title: "How often can I visit my parents in an Adult Family Home?",
      content:
        "  Families can visit as much or as little as they please. Family interaction is encouraged by providers and the adult family home will usually coordinate contact between family and resident based on care schedule/needs.",
    },
  ];

  return (
    <div>
      <div className="families">
        <CommonNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="familybanner">
              <h1 style={{ paddingBottom: "5rem" }}>
                MAKING SENIOR CARE SIMPLE
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="family-section-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-12 section2img">
              <div className="family-section2-img">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720677656/family-section2_xj04u0.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-12">
              <div className="family-content">
                <p>
                  Solutions For Families, By Families Planning for long-term
                  care can be a daunting task, but it doesn't have to be. Your
                  loved ones deserve the best care possible, and with our 25
                  years and beyond of combined experience, we can help you find
                  the care your family needs.
                </p>

                <p>
                  Every year, thousands of families across the United States
                  have to consider senior care options for their loves ones and
                  how to best care for their aging parents. When considering
                  care options, families have two choices: a huge retirement
                  community or a personalized adult family home. An adult family
                  home is a long-term senior care option that provides
                  personalized and skilled healthcare for the elderly, as well
                  as room and board, for up to eight seniors in a residential
                  home.
                </p>

                <p>
                  Adult Family Homes raise the standard of senior living by
                  providing elderly residents with a true 'home feel'. A high
                  staff to resident ratio means more detailed attention to
                  hygiene, medication management, and engaging activities for
                  senior residents.
                </p>

                <p>
                  We encourage families to use our site to search, filter, and
                  connect with providers and homes that interest them most. Once
                  you've found various suitable options, the next step is to
                  tour the residence with one of our Senior Placement
                  Specialists. Here are some things you should keep in mind when
                  touring an adult family home.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="family-question mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 centerclass">
              <div className="ask-question">
                <h1>What to Ask Providers</h1>
                <div className="offset-2">
                  {initialvalue.map((value) => (
                    <>
                      <div className="quebox1">
                        <p>{value.ques1}</p>
                      </div>
                      <div className="rightbox">
                        <div className="quebox2">
                          <p> {value.ques2}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                {/* {initialvalue.map((value) => (
                 
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="questwithans">
        <div className="container">
          <div className="row">
            <div className="faq">
              <div className="stars mt-5">
                <span className="fa fa-star checked"> </span>
                <span style={{ color: "#61ce70" }}>Ask Questtion</span>
              </div>
              <h2>
                Additional Questions Families Have About Adult Family Homes
              </h2>
              <Accordion defaultActiveKey="0">
                <div className="accordion accordionbox">
                  {faqData.map((item) => (
                    <Accordion.Item eventKey={item.id}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>{item.content}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Families;
