// src/components/TestimonialsCarousel.js
import React, { useEffect, useState } from "react";
import * as API from "../../Components/util/url";
import "./Reviews.css";
// Sample review data
const reviews = [
  {
    id: 1,
    name: "Jane",
    role: "Frontend Developer",
    comment:
      "I think even if I did the research mentioned above, it would be a total waste of money because if those people don't believe in the testimonials, they won't believe in the research at all. They would say that I made it all up. So why waste money?",
    url: "https://images.unsplash.com/photo-1485875437342-9b39470b3d95?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8d29tZW58ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    id: 2,
    name: "Mike",
    role: "ASP.NET Developer",
    comment:
      "A testimonial is an honest endorsement of your product or service that usually comes from a customer, colleague, or peer who has benefited from or experienced success as a result of the work you did for them.",
    url: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  },
  {
    id: 3,
    name: "Rick",
    role: "Frontend Developer",
    comment:
      "Your testimonial page serves as a platform to show off how others have benefited from your product or service, making it a powerful tool for establishing trust and encouraging potential buyers to take action.",
    url: "https://media.istockphoto.com/photos/shot-of-a-young-businessman-working-on-his-laptop-at-his-desk-picture-id1389465862?b=1&k=20&m=1389465862&s=170667a&w=0&h=J_npFHoiIBLGz_S7Y8TxY6aDcA5M3X2HRnAMqF13ZZY=",
  },
  {
    id: 4,
    name: "Tanya",
    role: "Web Developer",
    comment:
      "To generate these glowing testimonials, you need to know who your happy, successful customers are — and that's where collecting and analyzing customer feedback comes in. Once you've found those customers and collected their reviews, it's time to turn that enthusiasm into effective, affordable marketing materials.",
    url: "https://images.unsplash.com/photo-1484399172022-72a90b12e3c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGdpcmx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    id: 5,
    name: "Tanya",
    role: "Web Developer",
    comment:
      "To generate these glowing testimonials, you need to know who your happy, successful customers are — and that's where collecting and analyzing customer feedback comes in. Once you've found those customers and collected their reviews, it's time to turn that enthusiasm into effective, affordable marketing materials.",
    url: "https://images.unsplash.com/photo-1484399172022-72a90b12e3c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGdpcmx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  },
];

const Reviews = ({ homeDataReviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [getreviewdata, setGetReviewsData] = useState([{}]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % getreviewdata.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + getreviewdata.length) % getreviewdata.length
    );
  };

  const currentReview = getreviewdata[currentIndex] || {};
  const { name, role, comment, url } = currentReview;
  // reviews api integration
  const GetReviewsById = async () => {
    try {
      const response = await API.GetReviewsByHomeId({
        id: homeDataReviews.stayId,
        pageNumber: 1,
        pageSize: 10,
      });
      if (response && response.data && response.data.data) {
        setGetReviewsData(response.data.data);
      } else {
        setGetReviewsData([]); // Handle empty or malformed response
      }
    } catch (error) {
      console.log(error);
      setGetReviewsData([]); // Handle errors gracefully
    }
  };

  console.log(getreviewdata, "getreviews");

  useEffect(() => {
    GetReviewsById();
  }, []);

  return (
    <section>
      <div className="container mt-4">
        <div className="card p-2">
          {Array.isArray(getreviewdata) && getreviewdata.length > 0 ? (
            getreviewdata.map((data, index) => (
              <div className="card-body" key={index}>
                <div className="nameandrating d-flex">
                  <h5
                    className="card-title"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data.title}
                  </h5>

                  {[...Array(data.stars)].map((_, starIndex) => (
                    <span key={starIndex} className="orderone">
                      <i
                        className={`fa-solid fa-star ratingreviews ${
                          starIndex < data.stars ? "filled" : ""
                        }`}
                      ></i>
                    </span>
                  ))}
                </div>
                <p className="comment">{data.content}</p>
                <p style={{ textTransform: "capitalize" }}>{data.userName}</p>
              </div>
            ))
          ) : (
            <p>No reviews available.</p>
          )}

          <div className="card-footer">
            <button onClick={handlePrev} aria-label="Previous review">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#2196F3"
                  d="m30.9 43l3.1-3.1L18.1 24L34 8.1L30.9 5L12 24z"
                />
              </svg>
            </button>
            <button onClick={handleNext} aria-label="Next review">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#2196F3"
                  d="M17.1 5L14 8.1L29.9 24L14 39.9l3.1 3.1L36 24z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
