import React, { useEffect, useState } from "react";
import "./BlogLandingPage.css";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import { Link, useLocation } from "react-router-dom";
import * as API from "../../Components/util/url";

const BlogLandingPage = () => {
  const location = useLocation();
  const { allblog } = location.state || {};
  const [showPopular, setShowPopular] = useState(true);
  const [showRecent, setShowRecent] = useState(false);
  const [getblogdata, setGetBlogData] = useState([]);
  const [activeButton, setActiveButton] = useState("popular"); // New state for tracking active button\

  const showPopularBlog = () => {
    setShowPopular(true);
    setShowRecent(false);
    setActiveButton("popular");
  };


  const showRecentBlog = () => {
    setShowRecent(true);
    setShowPopular(false);
    setActiveButton("recent");
  };

  const landingBlogs = [
    {
      id: 1,
      title: "Do's and Dont's Dealing with Dementia Behavior Problems",
      blogImg:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1574336887/afh/blog/images/tti3dviuwaeg8czlwh1n.jpg",
      blogDate: "2019-11-21",
    },
    {
      id: 2,
      title: "Health Reminders for Seniors",
      blogImg:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1579957984/afh/blog/images/rliilgg3yz2raxeurmzy.png",
      blogDate: "2020-01-27",
    },
    {
      id: 3,
      title: "Technology for the Aged: Immensely Helpful for the Elderly",
      blogImg:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg",
      blogDate: "2020-02-10",
    },
  ];

  // api integration

  const GetAllBlogById = async (id) => {
    try {
      const response = await API.GetAllBlogsById(id);
      setGetBlogData(response.data.result);
      console.log(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    
    console.log("get blog landing");
    if (allblog) {
      GetAllBlogById(allblog);
    }
  }, []);
  return (
    <>
      <CommonNavbar style={{ backgroundColor: "#0c160f" }} />
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-12 left-section">
            <div className="blog-section">
              <h2>
                {getblogdata.title}
              
              </h2>
              <h6 style={{fontWeight:'600'}}>Created By:  <span style={{textAlign:'right',color:'gray'}}>{getblogdata.createdBy}</span></h6>
              <h6 style={{fontWeight:'600'}}>Posted On:  <span style={{textAlign:'right',color:'gray'}}>{getblogdata.datetime}</span></h6>
              <img
                src={getblogdata.image}
                alt="blog-landing-img"
              />

              <h3 className="mt-3" style={{textTransform:'uppercase'}}>
               {getblogdata.keywords}
              </h3>
              <p className="mt-3">
               {getblogdata.metadescription}
              </p>
              <p className="mt-3">
               {getblogdata.messageBody}
              </p>
              
             
             
            </div>
            {/* <div className="blog-comment mt-4">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <input type="text" name="" id="" placeholder="Name" />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <input type="email" name="" id="" placeholder="Email" />
                </div>
                <div className="col-lg-12 col-md-12 col-12 mt-2">
                  <textarea name="" id="landing-textarea"></textarea>
                </div>
                <div className="col-lg-12 col-md-12 col-12 mt-4 mb-4">
                  <Link to="" className="landing-submit-btn">
                    Submit
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-lg-5 col-md-12 col-12 right-section">
            <div className="landing-recent-blog ">
              <div className="recent-blog d-flex">
                <button
                  onClick={showPopularBlog}
                  className={`latestbtn ${
                    activeButton === "popular" ? "active" : ""
                  }`}
                >
                  Most Popular
                </button>
                <button
                  onClick={showRecentBlog}
                  className={`latestbtn latest-recent ${
                    activeButton === "recent" ? "active" : ""
                  }`}
                >
                  Recent
                </button>
              </div>

              {showPopular && (
                <>
                  {landingBlogs.map((blog) => (
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-3 col-4 landing-blog-post">
                        <div className="popular-sec-img">
                          <img src={blog.blogImg} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-6 landing-blog-post">
                        <div className="popular-sec-content">
                          <p>{blog.title}</p>
                          <div className="date-sec">
                            <p>Posted On {blog.blogDate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {showRecent && (
                <>
                  {landingBlogs.map((blog) => (
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-3 col-4 landing-blog-post">
                        <div className="popular-sec-img">
                          <img src={blog.blogImg} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-6 landing-blog-post">
                        <div className="popular-sec-content">
                          <p>{blog.title}</p>
                          <div className="date-sec">
                            <p>Posted On {blog.blogDate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {/* <div className="newspaper mt-4">
              <h3>Newsletter Signup</h3>
              <div className="line"></div>
              <p className="mt-2">
                Get the latest news, tips, and advice on senior care and adult
                family homes.
              </p>
            </div>
            <div className="landing-blog-email d-flex">
              <input type="email" name="" id="" placeholder="Email" />
              <Link to="" className="subscribe-btn">
                Subscribe
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogLandingPage;
