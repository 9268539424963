import React, { useEffect } from "react";
// import CommonNavbar from '../Pages/CommonNavbar'
import LandingBanner from "../Pages/LandingBanner";
import Steps from "../Pages/Steps";
import Benefits from "../Pages/Benefits";
import Contactus from "../Pages/Contactus";
import Footer from "../Pages/Footer";
import Medicaid from "../Pages/Medicaid";
import "./MediaQuery/MediaQuery.css";
import CookieConsent from "../Common/CookieConsent/CookieConsent";

const Home = () => {
  useEffect(() => {
    // Dynamically inject the gtag.js script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-126986689-1';
    document.head.appendChild(script);

    // Initialize gtag and set up the configuration
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){ window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'UA-126986689-1');
    };

    // Clean up the script if the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); 

  return (
    <div>
      {/* <CommonNavbar /> */}
      <CookieConsent/>
      <LandingBanner />
      <Steps />
      <Benefits />
      <Contactus />
      <Medicaid />
      <Footer />
    </div>
  );
};

export default Home;
