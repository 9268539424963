import React, { useEffect, useState } from "react";
import "./AllListing.css";
import SearchDashboard from "../../../Common/searchdashboard/SearchDashboard";
import PaginationWrapper from "../../../Common/Pagination/Pagination";
import * as API from "../../../Components/util/url";

const AllListing = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [allListing, setAllListing] = useState([]);
  const [singleHead, setSingleHeads] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [size, setSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  //
  const getSerialNumber = (page, size, index) => {
    return (page - 1) * size + (index + 1);
  };
  // Delete API
  const deleteAll = async () => {
    try {
      const idsToDelete = selectedAccounts.map((result) => result.stayId);
      console.log(idsToDelete, "IDs to delete");

      const deleteresp = await API.DeleteListingById({
        ids: idsToDelete,
      });
      console.log(deleteresp, "delete response");

      // Refresh the list after deletion
      AllListing(); // Change from AllAccounts() to fetchAllAccounts()
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedAccounts(allListing); // Select all accounts
    } else {
      setSelectedAccounts([]); // Deselect all
    }
  };

  const handleAccountSelection = (account) => {
    if (selectedAccounts.includes(account)) {
      setSelectedAccounts(selectedAccounts.filter((acc) => acc !== account)); // Deselect
    } else {
      setSelectedAccounts([...selectedAccounts, account]); // Select
    }
  };
  // api integration

  const AllListing = async (data, name = "") => {
    try {
      let response = await API.GetAllListing({
        name,
        pageNumber: pageCount,
        pageSize: size,
      });
      setAllListing(response.data.result);
      singleHeadHandler(response.data.result[0]);

      console.log(response.data.status);
      console.log(response.data.result.length);


      if (response.data.status && response.data.result.length > 0) {
        setSize(response.data.result.length);
        setPageCount(Math.ceil(response.data.totalPages / size));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let timerOut = setTimeout(() => {
      AllListing(singleHead);
    }, 800);
    return () => clearTimeout(timerOut);
  }, [size, activePage]);


  const singleHeadHandler = (data) => {
    setSingleHeads([data]);
  };

  // search handler
  const HandleSearch = (searchData) => {
    console.log(searchData);

    // Initialize obj as a string
    let obj = "";

    // Create a string from singleHead mapping each key to searchData
    Array.from(singleHead)?.forEach((data) => {
      obj += ` ${searchData}, `;
    });

    // Trim the trailing comma and space
    obj = obj.trim().slice(0, -1);

    console.log("Search String:", obj);

    // Pass the string to AllListing
    AllListing(1, obj);
  };

  return (
    <div className="mt-5  m-auto container">
       <h1 className="table-heading">All Listing</h1>
      <div className="container seachbar m-auto  ">
        <SearchDashboard
          style={{ marginBottom: "20px" }}
          onSearch={HandleSearch}
          onDelete={deleteAll} // Ensure this prop is named correctly
        />
      </div>
      <div className="table_responsive mt-3">
        <table className="tablemain">
          <thead className="tableheader">
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="table-checkbox"
                  id="table-checkbox-all"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th>Sr.No</th>
              <th>Home Name</th>
              <th>Owner Name</th>
              <th>City</th>
              <th>Contact</th>
              {/* <th>Publish</th>
            <th>Availability</th> */}
              <th>Owner Email</th>
              {/* <th>Enquiry</th> */}
              <th>Zip Code</th>
              <th>Map Coordinator</th>
              <th>Date</th>
              {/* <th>Delete</th> */}
            </tr>
          </thead>
          <tbody>
            {allListing &&
              allListing.map((listing, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      name="table-checkbox"
                      id={`table-checkbox-${index}`}
                      checked={selectedAccounts.includes(listing)}
                      onChange={() => handleAccountSelection(listing)}
                    />
                  </td>
                  <td>{getSerialNumber(activePage, size, index)}</td>
                  <td>{listing.pgName}</td>
                  <td>{listing.ownerName}</td>
                  <td>{listing.city}</td>
                  <td>{listing.mobile}</td>
                  {/* <td>
                {" "}
                <div className="container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div
                      className={`slider round ${isChecked ? "checked" : ""}`}
                    ></div>
                  </label>
                </div>
              </td> */}
                  {/* <td>
                <div className="container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div
                      className={`slider round ${isChecked ? "checked" : ""}`}
                    ></div>
                  </label>
                </div>
              </td> */}
                  <td>{listing.email}</td>
                  {/* <td>
                <i className={listing.enquiry}></i>
              </td> */}
                  <td>{listing.zipCode}</td>
                  <td>{listing.mapCordinates}</td>
                  <td>{listing.date}</td>
                  {/* <td>
                    <i className={listing.deleteIcon}></i>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationWrapper
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  );
};

export default AllListing;
