import React from "react";
import { Link, NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md col-12">
              <div className="footer-head">
                <NavLink to="/">
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
                    alt=""
                  />
                </NavLink>

                {/* <h1>Senior Citizen Medical & Health Checkup</h1> */}
              </div>
              <div className="footer-social  d-flex mt-5">
                <div className="facebook">
                  <Link to='https://www.facebook.com/adultfamilyhomes.org.2024?mibextid=LQQJ4d' target="_blank">
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                </div>
                <div className="twiiter">
                  <Link to='https://x.com/i/flow/login?redirect_after_login=%2FAdultFamilyHoms' target='_blank'>
                  <i class="fa-brands fa-x-twitter"></i>
                  </Link>
                </div>
                <div className="insta">
                  <Link>
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </div>
                {/* <div className="linkdin">
                  <Link>
                    <i class="fa-brands fa-linkedin"></i>
                  </Link>
                </div> */}
                <div className="youtube">
                  <Link>
                    <i class="fa-brands fa-youtube"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12">
              <div className="stars ">
                {/* <span
                  className="fa fa-star checked "
                  style={{ paddingRight: "10px" }}
                >
                  {" "}
                </span> */}
                <span className="footerul">About Us</span>
                <ul className="uldata">
                  <li>
                    <Link className="ulLink" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="ulLink" to="/user/home-owner">
                      List Your Homes
                    </Link>
                  </li>
                  <li>
                    <Link className="ulLink" to="/blog">
                      Our Blog
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="ulLink">Compare</Link>
                  </li> */}
                  <li>
                    <Link className="ulLink" to="/signin">
                      Sign In
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12">
              <div className="stars ">
                {/* <span
                  className="fa fa-star checked "
                  style={{ paddingRight: "10px" }}
                >
                  {" "}
                </span> */}
                <span className="footerul">Useful Links</span>
                <ul className="uldata">
                  <li>
                    <Link className="ulLink" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className="ulLink" to="/families">
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link className="ulLink">Upcoming Events</Link>
                  </li>
                  <li>
                    <Link className="ulLink" to='/provider'>Become a Provider</Link>
                  </li>
                  {/* <li>
                    <Link className="ulLink" to='/user/home-owner'>AFH Owner</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12">
              <div className="stars ">
                {/* <span
                  className="fa fa-star checked "
                  style={{ paddingRight: "10px" }}
                >
                  {" "}
                </span> */}
                <span className="footerul">Policies</span>
                <ul className="uldata">
                  <li>
                    <Link className="ulLink" to='/privacyPolicy'>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link className="ulLink" to='/cancellationPolicy'>Cancellation Policy</Link>
                  </li>
                  <li>
                    <Link className="ulLink" to='/Terms&Condition'>Terms and Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
