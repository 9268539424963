import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import Home from "./Components/Home";
import Families from "./Components/Families/Families";
import Provider from "./Components/Provider/Provider";
import Profile from "./Components/UserDashboardPages/Profile/Profile";
import Blog from "./Components/Blog/Blog";
import ContactUsPage from "./Components/ContactUsPage/ContactUsPage";
import Medicaidpage from "./Components/Medicaidpage/Medicaidpage";
import BlogLandingPage from "./Components/BlogLandingPage/BlogLandingPage";
import WebsiteLayout from "./Common/WebsiteLayout";
import AdminContactUS from "./Components/AdminPages/AdminContactUs/AdminContactUS";
import AllListing from "./Components/AdminPages/AllListing/AllListing";
import UserWebsiteLayout from "./Common/UserWebsiteLayout";
import AdminDashboard from "./Components/AdminPages/AdminDashboard/AdminDashboard";
import AllAccounts from "./Components/AdminPages/AllAccounts/AllAccounts";
import UserDashboardHomeOwner from "./Components/UserDashboardPages/UserDashboardHomeOwner/UserDashboardHomeOwner";
import UserDashboardFaq from "./Components/UserDashboardPages/UserDashboardFaq/UserDashboardFaq";
import SignIn from "./Common/SignIn/SignIn";
import OwnerWebsiteLayout from "./Common/OwnerWebsiteLayout";
import OwnerAFHServices from "./Components/OwnerPages/OwnerAFHServices/OwnerAFHServices";
import OwnerDashboardPage from "./Components/OwnerPages/OwnerDashboardPage/OwnerDashboardPage";
import OwnerProvider from "./Components/OwnerPages/OwnerProvider/OwnerProvider";
import OwnerListedHomes from "./Components/OwnerPages/OwnerListedHomes/OwnerListedHomes";
import ListedHomeLandingPage from "./Components/OwnerPages/ListedHomeLandingPage/ListedHomeLandingPage";
import HomeListingFilter from "./Components/HomeListingFilter/HomeListingFilter";
import WishListCards from "./Components/WishListCards/WishListCards";
import ReactGA from 'react-ga';

import AddReviews from "./Components/AddReviews/AddReviews";
import ProgressSteps from "./Components/Progressbarcomponent/Progressbarcomponent";
import Progressbarcomponent from "./Components/Progressbarcomponent/Progressbarcomponent";
import ListingHomeForm from "./Components/ListingHomeForm/ListingHomeForm";

import ProtectedRoutes from "./Components/ProtectedRoutes";
import AdminBlogPage from "./Components/AdminPages/AdminBlogPage/AdminBlogPage";
import ApplicationProvider from "./Components/AdminPages/ApplicationProvider/ApplicationProvider";
import PrivacyPolicy from "./Components/Policies/PrivacyPolicy";
import CancellationPolicy from "./Components/Policies/CancellationPolicy";
import TermsAndCondition from "./Components/Policies/TermsAndCondition";
import ListingHomeFormEdit from "./Components/ListingHomeFormEdit";
// import ListingHomeForm from "./Components/ListingHomeForm/ListingHomeForm";
function App() {
  const [userRole, setUserRole] = useState(null);
   // null, 'user', or 'owner'
   const navigate = useNavigate();
  const [signUpMode, setSignUpMode] = useState(false);

  const toggleSignUpMode = (mode) => {
    setSignUpMode(mode);
  };
  // goggle analytics

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
 
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />

        {/* Login - Signup */}
        <Route path="/signin" element={<SignIn signUpMode={signUpMode} />} />
        <Route path="/signup" element={<SignIn signUpMode={signUpMode} />} />

        <Route path="/families" element={<Families />} />
        <Route path="/providers" element={<Provider />} />
        <Route path="/medicaid" element={<Medicaidpage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/blogLandingPage/:blogId" element={<BlogLandingPage />} />
        <Route path="/wishlist" element={<WishListCards />} />
         
        <Route path="/addreviwes" element={<AddReviews/>}/>
        <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
        <Route path="/cancellationPolicy" element={<CancellationPolicy/>}/>
        <Route path="/Terms&Condition" element={<TermsAndCondition/>}/>
        <Route path="/progressbar" element={<Progressbarcomponent/>}/>
        <Route
          path="/list-home-LandingPage/:stayId"
          element={<ListedHomeLandingPage />}
        />
        <Route
          path="/seniorcare-homeList/:city"
          element={<HomeListingFilter />}
        />

        {/* user-dashboard */}
        <Route path="/user" element={<UserWebsiteLayout />}>
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/user/faq" element={<UserDashboardFaq />} />
          <Route path="/user/home-owner" element={<ListingHomeForm/>} />
          <Route path="/user/provider" element={<OwnerProvider />} />
        </Route>

        {/* owner-dashboard */}
        <Route path="/owner" element={<OwnerWebsiteLayout />}>
          {/* <Route
            path="/owner/add-new-home"
            element={<UserDashboardHomeOwner />}
          /> */}
          <Route path="/owner/add-new-home" element={<ListingHomeForm/>}/>
          <Route path="/owner/afh-services" element={<OwnerAFHServices />} />
          <Route path="/owner/faq" element={<UserDashboardFaq />} />
          <Route path="/owner/dashboard" element={<OwnerDashboardPage />} />
          <Route path="/owner/provider" element={<OwnerProvider />} />
          <Route path="/owner/listed-homes" element={<OwnerListedHomes />} />
          <Route path="/owner/listed-homesEdit" element={<ListingHomeFormEdit />} />
        </Route>

        {/* admin dashborad */}
        
       {/* <Route path="/supernova" element={<ProtectedRoutes />}> */}
       {/* <Routes> */}
        {/* Admin Dashboard (Protected with admin role) */}
        <Route path="/supernova" element={<ProtectedRoutes role="admin"><WebsiteLayout /></ProtectedRoutes>}>
  <Route index element={<AdminDashboard />} />
  <Route path="all-listing" element={<AllListing />} />
  <Route path="contact-us" element={<AdminContactUS />} />
  <Route path="all-accounts" element={<AllAccounts />} />
  <Route path="admin-blog" element={<AdminBlogPage />} />
  <Route path="Application-Provider" element={<ApplicationProvider />} />
</Route>

        {/* <Routes/> */}
      {/* </Route> */}
        {/* Redirect based on user role */}
        <Route
          path="/redirect"
          element={
            userRole === "admin" ? (
              <navigate to="/supernova" replace />
            ) : userRole === "owner" ? (
              <navigate to="/owner/provider" replace />
            ) : userRole === "user" ? (
              <navigate to="/user/provider" replace />
            ) : (
              <navigate to="/" replace />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
