import React, { useEffect, useState, useRef } from "react";
import leaflet from "leaflet";
import useLocalStorage from "../../Components/hooks/useLocalStorage";
import useGeolocation from "../../Components/hooks/useGeoloaction";

const LandingMapIntegration = ({homePosition}) => {
  const mapRef = useRef(null); // Reference to the map div
  const userMarkerRef = useRef(null); // Reference to the marker
  const [userPosition, setUserPosition] = useState({
    latitude: parseFloat(homePosition.latitude),
    longitude: parseFloat(homePosition.longitude)
  })
 const location={
    latitude: parseFloat(homePosition.latitude),
    longitude: parseFloat(homePosition.longitude)
  }
  // Initialize the map
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current = leaflet
        .map(mapRef.current)
        .setView([userPosition.latitude, userPosition.longitude], 13);

      leaflet
        .tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "&copy; OpenStreetMap contributors",
        })
        .addTo(mapRef.current);
    }
  }, []);


  useEffect(() => {
 setUserPosition({...userPosition});
if(userMarkerRef.current){
    mapRef.current.removeLayer(userMarkerRef.current)
}


 userMarkerRef.current=leaflet.marker([location.latitude,location.longitude]).addTo(mapRef.current);
 mapRef.current.setView([location.latitude, location.longitude]);
  }, []);

  return (
    <div
      id="map"
      ref={mapRef}
      style={{ height: "400px", width: "100%" }}
    />
  );
};

export default LandingMapIntegration;
