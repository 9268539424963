import React, { useState } from "react";
import "./Pagination.css"; // Import your CSS file if you have styles

const Pagination = ({ type, pageCount, activePage, setActivePage }) => {
  // const pageCount = 10; // Example number of pages
  // const [activePage, setActivePage] = useState(1); // State to keep track of the active page
  const prevText = type === "pagination-1" ? "Prev" : "«";
  const nextText = type === "pagination-1" ? "Next" : "»";

  const pages = Array.from({ length: pageCount }, (_, index) => index + 1);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const handlePrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextPage = () => {
    if (activePage < pageCount) {
      setActivePage(activePage + 1);
    }
  };

  return (
    <div className="pagination">
      <ul className={type}>
        <li
          className={`page-number prev${activePage === 1 ? " disabled" : ""}`}
        >
          <a href="#" onClick={handlePrevPage}>
            {prevText}
          </a>
        </li>
        {pages.map((page) => (
          <li
            key={page}
            className={`page-number${activePage === page ? " active" : ""}`}
          >
            <a href="#" onClick={() => handlePageChange(page)}>
              {page}
            </a>
          </li>
        ))}
        <li
          className={`page-number prev${
            activePage === pageCount ? " disabled" : ""
          }`}
        >
          <a href="#" onClick={handleNextPage}>
            {nextText}
          </a>
        </li>
      </ul>
    </div>
  );
};

const PaginationWrapper = ({ pageCount, activePage, setActivePage }) => {
  return (
    <div className="container">
      {/* <Pagination type="pagination-1" />
      <Pagination type="pagination-2" /> */}
      <Pagination
        type="pagination-3"
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  );
};

export default PaginationWrapper;
