import React, { useState, useEffect } from "react";
import "animate.css";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as API from "../../Components/util/url";
import { First } from "react-bootstrap/esm/PageItem";
import CommonModal from "../../Common/CommonModal/CommonModal";
import CommonModalFail from "../../Common/CommonModal/CommonModalFail";
import { Navigate, useNavigate } from "react-router-dom";

let imageCloud1 = "";
let imageCloud2 = "";
let videocloud = "";
const ListingHomeForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [fileNames, setFileNames] = useState({
    thumbnail: "",
    moreImages: "",
    video: "",
    // description: "",
  });
  const [filePreviews, setFilePreviews] = useState({
    thumbnail: "",
    moreImages: "",
    video: "",
  });

  //   let dataStore = {};
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [dataStore, setDataStore] = useState({});
  const [lastformdata, setLastFormData] = useState({});
  const [alldataStore, setAllDataStore] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [cloudImage1, setCloudImage1] = useState("");
  const [cloudImage2, setCloudImage2] = useState("");
  const [videourl, setVedioUrl] = useState("");
  const [image, setImage] = useState("");
  const [selectmedicaid, setSelectMedicaid] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [metaDescriptionCount, setMetaDescriptionCount] = useState(1); // Max characters for meta description
  const [descriptionCount, setDescriptionCount] = useState(1);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const displayStep = (stepNumber) => {
    if (stepNumber >= 1 && stepNumber <= 4) {
      setCurrentStep(stepNumber);
    }
  };
  const amenities = [
    {
      Amenities: "Activities coordinator",
    },
    {
      Amenities: "Telephone",
    },
    {
      Amenities: "Internet access",
    },
    {
      Amenities: "Wheelchair accessible",
    },
    {
      Amenities: "Beautician",
    },
    {
      Amenities: "Library on Wheels",
    },
    {
      Amenities: "Tv in room",
    },
    {
      Amenities: "Hoyer Lift",
    },
    {
      Amenities: "Emergency call buttons",
    },
    {
      Amenities: "Outdoor Common Area",
    },
    {
      Amenities: "Cable TV",
    },
    {
      Amenities: "Newspaper",
    },
    {
      Amenities: "Generator",
    },

    {
      Amenities: "Games",
    },
    {
      Amenities: "Arts and Crafts",
    },
    {
      Amenities: "Birthday celebration",
    },
    {
      Amenities: "Music",
    },
    {
      Amenities: "Hospital Electric bed Available in the room",
    },
  ];
  const servies = [
    {
      Services: "Private Room",
    },
    {
      Services: "Room & Bath",
    },
    {
      Services: "Room & Shower",
    },
    {
      Services: "Semi-private Room",
    },
    {
      Services: "Shared Main Shower",
    },
    {
      Services: " Medication Delivery",
    },
    {
      Services: " Stroke",
    },
    {
      Services: "Female Residents Preferred",
    },
    {
      Services: "24 hour care",
    },
    {
      Services: " Medication Management",
    },
    {
      Services: "Visual/Hearing Impaired",
    },
    {
      Services: " Male Residentes Preferred",
    },
    {
      Services: " Dementia/Alzheimer",
    },
    {
      Services: "Oxygen Therapy",
    },
    {
      Services: "Wound Care",
    },
    {
      Services: " Medicaid Accepted",
    },
    {
      Services: "Developmental Disabilities",
    },
    {
      Services: "Parkinson's",
    },
    {
      Services: "Doctor On Site",
    },
    {
      Services: "Doctor On Site",
    },
    {
      Services: "Home owner lives in the house & provides care",
    },
    {
      Services: " Diabetes",
    },
    {
      Services: "Pet Care",
    },
    {
      Services: "Feeding Tube Resident's Welcomed",
    },
  ];
  const medicaids = [
    { Medicaid: "Accepted After 6 months of Private Pay" },
    { Medicaid: "Accepted After 1 year of Private Pay" },
    { Medicaid: "Accepted After 2 year of Private Pay" },
    { Medicaid: "Accepted After 3 year of Private Pay" },
  ];

  const updateProgressBar = () => {
    return ((currentStep - 1) / 3) * 100;
  };
  const handleFileInputClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      setFileNames({
        ...fileNames,
        [key]: file.name,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviews({
          ...filePreviews,
          [key]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setFileNames({
        ...fileNames,
        [key]: "",
      });
      setFilePreviews({
        ...filePreviews,
        [key]: "",
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setText(e.target.value);
    console.log(text, "text");
    if (e.target.value.length >= 1000) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // Update the form data for all inputs
    setLastFormData({
      ...lastformdata,
      [name]: value,
    });

    // Count characters and enforce a 1000 character limit
    if (name === "metadescription") {
      if (value.length <= 1000) {
        setLastFormData({ ...lastformdata, [name]: value });
        setMetaDescriptionCount(value.length); // Update the count
      } else {
        // Optionally show an error message or restrict input
        setMetaDescriptionCount(1000); // Limit to 1000 characters
      }
    }

    if (name === "description") {
      if (value.length <= 1000) {
        setLastFormData({ ...lastformdata, [name]: value });
        setDescriptionCount(value.length); // Update the count
      } else {
        // Optionally show an error message or restrict input
        setDescriptionCount(1000); // Limit to 1000 characters
      }
    }
  };
  const handleInputChangeweb = (e) => {
    const { name, value } = e.target;


    if (e.target.value.length >= 1000) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // Update the form data for all inputs
    setLastFormData({
      ...lastformdata,
      [name]: value,
    });

    // Count characters and enforce a 1000 character limit
    // if (name === "metadescription") {
    //   if (value.length <= 1000) {
    //     setLastFormData({ ...lastformdata, [name]: value });
    //     setMetaDescriptionCount(value.length); // Update the count
    //   } else {
    //     // Optionally show an error message or restrict input
    //     setMetaDescriptionCount(1000); // Limit to 1000 characters
    //   }
    // }

    // if (name === "description") {
    //   if (value.length <= 1000) {
    //     setLastFormData({ ...lastformdata, [name]: value });
    //     setDescriptionCount(value.length); // Update the count
    //   } else {
    //     // Optionally show an error message or restrict input
    //     setDescriptionCount(1000); // Limit to 1000 characters
    //   }
    // }
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setText2(e.target.value);
    console.log(text2, "text2");

    if (e.target.value.length >= 1000) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // Update the form data for all inputs
    setLastFormData({
      ...lastformdata,
      [name]: value,
    });

    // Count characters and enforce a 1000 character limit
    if (name === "metadescription") {
      if (value.length <= 1000) {
        setLastFormData({ ...lastformdata, [name]: value });
        setMetaDescriptionCount(value.length); // Update the count
      } else {
        // Optionally show an error message or restrict input
        setMetaDescriptionCount(1000); // Limit to 1000 characters
      }
    }

    if (name === "description") {
      if (value.length <= 1000) {
        setLastFormData({ ...lastformdata, [name]: value });
        setDescriptionCount(value.length); // Update the count
      } else {
        // Optionally show an error message or restrict input
        setDescriptionCount(1000); // Limit to 1000 characters
      }
    }
  };
  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };
  // form
  const form1 = (values) => {
    const form1data = { ...dataStore, ...values, ...selectmedicaid };

    setDataStore(form1data);
    console.log(form1data, "First form data stored");
  };
  const handleMedicaidChange = (medicaid) => {
    const medicaidValue = medicaid.Medicaid;
    setSelectMedicaid((prevMedicaid) =>
      prevMedicaid.includes(medicaidValue)
        ? prevMedicaid.filter((m) => m !== medicaidValue)
        : [...prevMedicaid, medicaidValue]
    );
  };
  const handleSubmit = async (e, value) => {
    console.log("onSubmit");

    e.preventDefault();
    const lastform = { ...lastformdata };
    setLastFormData(lastform);
    console.log(imageCloud1);

    // console.log(lastform, "value data");
    setAllDataStore(
      dataStore,
      selectedServices,
      value,
      selectedAmenities.values,
      imageCloud1,
      imageCloud2,
      videourl
    );

    const imageUrlOne = await handleUpload();
    const imageUrlTwo = await handleUploadmore();
    const videoUrl = await handlevedioUploads();
    // if (imagePreview !== "") {
    //   const imageUrlOne = await handleUpload();
    //   // const imageUrlTwo = await handleUpload();
    // }

    await CreateListing(value);
  };

  //  }
  const form2 = () => {
    console.log(selectedServices, "services");
  };
  const form3 = () => {
    console.log(selectedAmenities, "amenities");
  };

  const handleServiceChange = (service) => {
    const serviceValue = service.Services;
    setSelectedServices((prevServices) =>
      prevServices.includes(serviceValue)
        ? prevServices.filter((s) => s !== serviceValue)
        : [...prevServices, serviceValue]
    );
  };

  const handleAmenitiesChange = (amenities) => {
    const amenitiesvalue = amenities.Amenities;
    setSelectedAmenities((preAmenties) =>
      preAmenties.includes(amenitiesvalue)
        ? preAmenties.filter((a) => a !== amenitiesvalue)
        : [...preAmenties, amenitiesvalue]
    );
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const initialValues = {
    homeName: "",
    ownerName: "",
    homeAddress: "",
    contactNumber: "",
    city: "",
    zip: "",
    email: "",
    webUrl: "",
    amenities: "",
    depositAmount: "",
    imageUrlOne: "",
    imageUrlTwo: "",
    videoUrl: "",
    description: "",
    roomTypes: "",
    medicaid: "",
    metadescription: "",
  };

  const validationSchema = Yup.object({
    homeName: Yup.string().required("Home Name is required"),
    ownerName: Yup.string().required("Owner Name is required"),
    homeAddress: Yup.string().required("Home Address is required"),
    contactNumber: Yup.string().required("Contact Number is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string().required("ZipCode is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    depositAmount: Yup.string().required("Deposit Amount is required"),
    imageUrlOne: Yup.string().required("Upload Image is required"),
    // description: Yup.string().required("Description is required"),
  });

  // api integration
  const CreateListing = async (data) => {
    try {
      console.log("Payload Data:", data);
      let response = await API.createlisting({
        homeName: dataStore.homeName,
        ownerName: dataStore.ownerName,
        homeAddress: dataStore.homeAddress,
        phoneNumber: dataStore.contactNumber,
        zipCode: dataStore.zip,
        city: dataStore.city,
        email: dataStore.email,
        depositAmount: dataStore.depositAmount,
        medicaids: selectmedicaid,
        roomTypes: selectedServices,
        amenities: selectedAmenities,
        activites: selectedAmenities,
        webUrl: lastformdata.webUrl,
        imageUrlOne: imageCloud1,
        imageUrlTwo: imageCloud2,
        videoUrl: videocloud,
        description: lastformdata.description,
        metadescription: lastformdata.metadescription,
      });
      console.log(response, "CreateListing");
      if (response.data.status === true) {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "",
        });
        setShowSuccessModal(true);
        navigate("/owner/listed-homes");
      } else {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "",
        });
        setShowFailureModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle image here
  // const imageHandler = (e, type) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const selectedFile = e.target.files[0];

  //     if (type === "mainImage") {
  //       // Handle main image upload
  //       console.log("Main image:", URL.createObjectURL(selectedFile));
  //       setCloudImage(selectedFile, 'mainImage');
  //     } else if (type === "moreImages") {
  //       // Handle more images upload
  //       console.log("More images:", URL.createObjectURL(selectedFile));
  //       setCloudImage(selectedFile, 'moreImages');
  //     }
  //   } else {
  //     console.error("No files selected.");
  //   }
  // };

  const handleMainImageUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      console.log("Main image:", URL.createObjectURL(selectedFile));
      setCloudImage1(selectedFile, "mainImage");
    }
  };

  const handleMoreImagesUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      console.log("More images:", URL.createObjectURL(selectedFile));
      setCloudImage2(selectedFile, "moreImages");
    }
  };
  const handlevedioUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      console.log("More images:", URL.createObjectURL(selectedFile));
      setVedioUrl(selectedFile, "moreImages");
    }
  };
  const handleUpload = async (e) => {
    // setLoading(true);
    console.log("handleUpload");

    console.log(cloudImage1, "ip");
    const file = cloudImage1;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("folder", "AFH/HomeImage");
    // formData.append("public_id", localStorage.getItem("userId"));
    const uniquePublicIdMain = `${localStorage.getItem(
      "userId"
    )}_main_${new Date().getTime()}`;
    formData.append("public_id", uniquePublicIdMain);

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dbtsrjssc/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setImage(data.secure_url);
      console.log(data.secure_url, "url image");
      imageCloud1 = data.secure_url;
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      // setLoading(false);
    }
  };
  const handleUploadmore = async (e) => {
    // setLoading(true);
    console.log("handleUpload");

    console.log(cloudImage2, "ip");
    const file = cloudImage2;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("folder", "AFH/HomeImage");
    // formData.append("public_id", localStorage.getItem("userId"));
    const uniquePublicIdMain = `${localStorage.getItem(
      "userId"
    )}_main_${new Date().getTime()}`;
    formData.append("public_id", uniquePublicIdMain);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dbtsrjssc/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setImage(data.secure_url);
      console.log(data.secure_url, "url image");
      imageCloud2 = data.secure_url;
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      // setLoading(false);
    }
  };
  const handlevedioUploads = async (e) => {
    // setLoading(true);
    console.log("handleUpload");

    console.log(videourl, "ip");
    const file = videourl;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("folder", "AFH/HomeImage");
    // formData.append("public_id", localStorage.getItem("userId"));
    const uniquePublicIdMain = `${localStorage.getItem(
      "userId"
    )}_main_${new Date().getTime()}`;
    formData.append("public_id", uniquePublicIdMain);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dbtsrjssc/video/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setImage(data.secure_url);
      console.log(data.secure_url, "url image");
      videocloud = data.secure_url;
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div id="container" className="container mt-5">
      <div className="progress px-1" style={{ height: "3px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${updateProgressBar()}%` }}
          aria-valuenow={updateProgressBar()}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      <div className="step-container d-flex justify-content-between">
        <div className="step-circle" onClick={() => displayStep(1)}>
          1
        </div>
        <div className="step-circle" onClick={() => displayStep(2)}>
          2
        </div>
        <div className="step-circle" onClick={() => displayStep(3)}>
          3
        </div>
        <div className="step-circle" onClick={() => displayStep(4)}>
          4
        </div>
      </div>

      <form id="multi-step-form">
        {/* Step 1 */}
        <div
          className={`step ${
            currentStep === 1
              ? "animate__animated animate__fadeInRight"
              : "d-none"
          }`}
        >
          <div className="mb-3">
            <div id="list-homes ">
              <h1 className="lists-homes-heading mt-4">General Information</h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                //   onSubmit={form1}
              >
                {({ values, formData }) => (
                  <>
                    <form>
                      <div className="container">
                        <div className="row">
                          {/* {} */}
                          <div className="col-lg-6 col-md-6 col-12 mt-2">
                            <Field
                              placeholder="Enter Home Name"
                              type="text"
                              name="homeName"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="homeName"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 mt-2">
                            <Field
                              placeholder=" Enter Your Name"
                              type="text"
                              name="ownerName"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="ownerName"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 mt-4">
                            <Field
                              placeholder="Home Address"
                              type="text"
                              name="homeAddress"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="homeAddress"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 mt-4">
                            <Field
                              placeholder="Contact Number"
                              type="number"
                              name="contactNumber"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="contactNumber"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 mt-4">
                            <Field
                              placeholder="City"
                              type="text"
                              name="city"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 mt-4">
                            <Field
                              placeholder="Zip"
                              type="number"
                              name="zip"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="zip"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 mt-4">
                            <Field
                              placeholder="Email"
                              type="email"
                              name="email"
                              className="list-homes-form"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-12 mt-4">
                            <label className="Field-name me-1">
                              Deposit Amount :{" "}
                            </label>
                            <Field
                              placeholder="Deposit Number"
                              type="number"
                              name="depositAmount"
                              className="deposit-btn"
                              min="0"
                            />
                            <ErrorMessage
                              name="depositAmount"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="medicaids">
                            <span>
                              Medicaid:
                              <i class="fa-solid fa-circle-info medicaid-icon"></i>
                            </span>
                          </div>
                          <div className="medicaid-info">
                            <div className="row">
                              {medicaids.map((data, index) => (
                                <div
                                  className="col-lg-6 col-md-12 col-12 options-margin"
                                  key={data.id || index}
                                >
                                  <input
                                    type="checkbox"
                                    value={data}
                                    onChange={() => handleMedicaidChange(data)}
                                    checked={selectmedicaid.includes(
                                      data.Medicaid
                                    )}
                                  />
                                  <label>{data.Medicaid}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* <div className="medicaids ">
                            <span>
                              Private Pay Rates:
                              <i class="fa-solid fa-circle-info medicaid-icon"></i>
                            </span>
                          </div>
                          <div className="medicaid-info">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-12 options-margin">
                                <input type="checkbox" name="" id="" />
                                <label>Independent Living</label>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 options-margin">
                                <input type="checkbox" name="" id="" />
                                <label>Stand By Assistance</label>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 options-margin">
                                <input type="checkbox" name="" id="" />
                                <label>Full Assistance</label>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 options-margin">
                                <input type="checkbox" name="" id="" />
                                <label>Intensive Assistance</label>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 options-margin">
                                <input type="checkbox" name="" id="" />
                                <label>Hospice End of Life Care </label>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                            {/* <Link to="" className="specialist-btn">
                                 Unlock Rates,Contact a Senior Placement Specialist
                               </Link> */}
                          </div>
                        </div>
                      </div>
                    </form>
                    <button
                      type="button"
                      className="btn next-step"
                      onClick={() => {
                        nextStep();
                        form1(values);
                      }}
                    >
                      Next
                    </button>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* Step 2 */}
        <div
          className={`step ${
            currentStep === 2
              ? "animate__animated animate__fadeInRight"
              : "d-none"
          }`}
        >
          <div className="mb-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              //    onSubmit={form1}
            >
              {({ values, formData }) => (
                <>
                  <form>
                    <div id="service">
                      {/* <Services_homeOwner /> */}
                      <div className="pb-5">
                        <h1 className="mb-3 mt-4">Services</h1>
                        <div className="row">
                          {servies.map((data, index) => (
                            <div
                              className="col-lg-6 col-md-6 mb-3"
                              key={data.id || index}
                            >
                              <div className="services-menu">
                                <input
                                  type="checkbox"
                                  value={data}
                                  onChange={() => handleServiceChange(data)}
                                  checked={selectedServices.includes(
                                    data.Services
                                  )}
                                />

                                <span style={{ marginLeft: "10px" }}>
                                  {data.Services}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="btn-flex">
                    <div className="nextbtn">
                      <button
                        type="button"
                        className="btn  prev-step"
                        onClick={prevStep}
                      >
                        Previous
                      </button>
                    </div>
                    <div className="previousbtn">
                      <button
                        type="button"
                        className="btn  next-step"
                        onClick={() => {
                          nextStep();
                          form2(values);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>

        {/* Step 3 */}
        <div
          className={`step ${
            currentStep === 3
              ? "animate__animated animate__fadeInRight"
              : "d-none"
          }`}
        >
          <div className="mb-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              //   onSubmit={form2}
            >
              <form>
                <div id="amentiies">
                  {/* <Amenities /> */}

                  <div className="pb-4">
                    <h1 className="mb-3 mt-4">Activities & Amenities</h1>
                    <div className="row mt-4">
                      {amenities.map((data, index) => (
                        <div
                          className="col-lg-6 col-md-6 col-12 mb-3 "
                          key={data.id || index}
                        >
                          <div className="services-menu">
                            <input
                              type="checkbox"
                              value={data}
                              onChange={() => handleAmenitiesChange(data)}
                              checked={selectedAmenities.includes(
                                data.Amenities
                              )}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {data.Amenities}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </form>
            </Formik>
          </div>

          <div className="btn-flex">
            <div className="nextbtn">
              <button
                type="button"
                className="btn  prev-step"
                onClick={prevStep}
              >
                Previous
              </button>
            </div>
            <div className="previousbtn">
              <button
                type="button"
                className="btn  next-step"
                onClick={(values) => {
                  nextStep();
                  form3(values);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        {/* Step 4 */}
        <div
          className={`step ${
            currentStep === 4
              ? "animate__animated animate__fadeInRight"
              : "d-none"
          }`}
        >
          <div className="mb-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={(e) => handleSubmit(e)}
            >
              <Form>
                <div id="description">
                  {/* <HomeDescription/> */}
                  <div className="pb-4">
                    <div className="row ">
                      <div className="col-lg-4 col-md-6 col-12 mt-3">
                        <div className="websiteUrl">
                          <label htmlFor="website-url">
                            Website Url{" "}
                            <i className="fa-solid fa-circle-info"></i>
                          </label>
                          <input
                            type="url"
                            id="website-url"
                            placeholder="Enter your URL"
                            className="mt-4"
                            name="webUrl"
                            value={lastformdata.videoUrl}
                            onChange={handleInputChangeweb}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt-3">
                        <div className="websiteUrl">
                          <label htmlFor="thumbnail-upload">
                            Upload Thumbnail for Your Home
                          </label>
                          <div className="file-input-wrapper mt-4">
                            <div className="form-group">
                              <Field
                                id="thumbnail-upload"
                                type="file"
                                name="imageUrlOne"
                                className="input-file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileInputChange(e, "thumbnail")
                                }
                                style={{ display: "none" }} // Hide the default file input
                              />
                              <ErrorMessage
                                name="imageUrlOne"
                                component="div"
                                className="text-danger"
                              />
                              <div className="input-group">
                                <span className="input-group-addon"></span>
                                {/* <input
                                  type="file"
                                  accept="image/*"
                                  className="form-control"
                                  // value={fileNames.thumbnail}
                                  disabled
                                  placeholder="Upload image"
                                  onChange={imageHandler}
                                /> */}
                                <input
                                  type="file"
                                  onChange={handleMainImageUpload}
                                  style={{ marginLeft: 0 }}
                                />
                                <span className="input-group-btn">
                                  {/* <button
                                    className="upload-field btn"
                                    type="button"
                                    onClick={() =>
                                      handleFileInputClick("thumbnail-upload")
                                    }
                                    // onClick={imageHandler}
                                  >
                                    <i className="fa fa-search"></i> Browse
                                  </button> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt-3">
                        <div className="websiteUrl">
                          <label htmlFor="more-images-upload">
                            Upload More Images
                          </label>
                          <div className="file-input-wrapper mt-4">
                            <div className="form-group">
                              <input
                                id="more-images-upload"
                                type="file"
                                name="imageUrlTwo"
                                className="input-file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileInputChange(e, "moreImages")
                                }
                                style={{ display: "none" }} // Hide the default file input
                              />
                              <div className="input-group">
                                <span className="input-group-addon"></span>
                                <input
                                  type="file"
                                  onChange={handleMoreImagesUpload}
                                  style={{ marginLeft: 0 }}
                                />
                                <span className="input-group-btn">
                                  {/* <button
                                    className="upload-field btn"
                                    type="button"
                                    onClick={() =>
                                      handleFileInputClick("more-images-upload")
                                    }
                                  >
                                    <i className="fa fa-search"></i> Browse
                                  </button> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt-3">
                        <div className="websiteUrl">
                          <label htmlFor="video-upload">
                            Upload Your Home Video
                          </label>
                          <div className="file-input-wrapper mt-4">
                            <div className="form-group">
                              <input
                                type="file"
                                onChange={handlevedioUpload}
                                style={{ marginLeft: 0 }}
                              />
                              <div className="input-group">
                                <span className="input-group-addon"></span>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  value={fileNames.video}
                                  disabled
                                  placeholder="Upload video"
                                /> */}
                                <span className="input-group-btn">
                                  {/* <button
                                    className="upload-field btn"
                                    type="button"
                                    onClick={() =>
                                      handleFileInputClick("video-upload")
                                    }
                                  >
                                    <i className="fa fa-search"></i> Browse
                                  </button> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="detail-description">
                          <label htmlFor="home-description">
                            Write Meta Description of Your Home
                          </label>
                          <br />
                          <Field
                            as="textarea"
                            id="home-description"
                            name="metadescription"
                            className="mt-2"
                            cols={5}
                            rows={5}
                            value={lastformdata.metadescription}
                            // value={text2}
                            onChange={handleInputChange2}
                            maxLength={1000}
                            disabled={isDisabled}
                          />

                          {/* {metaDescriptionCount} characters remaining */}

                          <div>{text2.length} / 1000 characters</div>
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="detail-description">
                          <label htmlFor="home-description">
                            Write Detailed Description of Your Home
                          </label>
                          <br />
                          <Field
                            as="textarea"
                            id="home-description"
                            name="description"
                            className="mt-2"
                            cols={5}
                            rows={5}
                            // value={text}
                            value={lastformdata.description}
                            onChange={handleInputChange}
                            maxLength={1000}
                            // disabled={isDisabled}
                          />
                          <div>{text.length} / 1000 characters</div>
                          {/* {descriptionCount} characters remaining */}
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-flex">
                  <div className="nextbtn">
                    <button
                      type="button"
                      className="btn  prev-step"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="previousbtn">
                    <button
                      className="btn btn-success"
                      type="submit"
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </form>
      <CommonModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message={message}
      />
      <CommonModalFail
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        message={message}
      />
    </div>
  );
};

export default ListingHomeForm;
