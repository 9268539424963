import { Field, Formik, ErrorMessage, Form } from "formik";
import React, { useEffect, useState } from "react";
// import { CKEditor } from "ckeditor4-react";
import "./AdminBlogPage.css";
import * as Yup from "yup";
import * as API from "../../../Components/util/url";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import CommonModalFail from "../../../Common/CommonModal/CommonModalFail";
import EditModal from "../../EditModal/EditModal";

let imageblog = "";
const AdminBlogPage = () => {
  const [cloudImage1, setCloudImage1] = useState("");
  const [fileNames, setFileNames] = useState({
    uploadimage: "",
  });
  const [filePreviews, setFilePreviews] = useState({
    uploadimage: "",
  });
  const [image, setImage] = useState("");
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const[blog,setBlog]=useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [blogdata, setBlogData] = useState([]);
  const [dataStore, setDataStore] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [editapicall, setEditAPiCall] = useState(false);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });
  const [editingBlog, setEditingBlog] = useState(null); // State to hold the blog being edited
  const [formDataa, setFormDataa] = useState({});
  // cloudnairy images

  const handleMoreImagesUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      console.log("More images:", URL.createObjectURL(selectedFile));
      setCloudImage1(selectedFile, "uploadimage");
    }
  };
  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      setFileNames({
        ...fileNames,
        [key]: file.name,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviews({
          ...filePreviews,
          [key]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setFileNames({
        ...fileNames,
        [key]: "",
      });
      setFilePreviews({
        ...filePreviews,
        [key]: "",
      });
    }
  };
  const handleUpload = async (e) => {
    // setLoading(true);
    console.log("handleUpload");

    console.log(cloudImage1, "ip");
    const file = cloudImage1;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("folder", "AFH/HomeImage");
    // formData.append("public_id", localStorage.getItem("userId"));
    const uniquePublicIdMain = `${localStorage.getItem(
      "userId"
    )}_main_${new Date().getTime()}`;
    formData.append("public_id", uniquePublicIdMain);

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dbtsrjssc/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setImage(data.secure_url);
      console.log(data.secure_url, "url image");
      imageblog = data.secure_url;
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      // setLoading(false);
    }
  };
  // Function to handle the opening of the modal
  const handleOpenModal = (blogId, i) => {
    console.log("index", i);

    setBlog(i)
    setSelectedBlogId(blogId); 
    console.log(selectedBlogId);
    
    setModalShow(true); 
  };
  const handleSubmit = async (values) => {
    const imageUrl = await handleUpload();
    const formdata = { ...dataStore, ...values };
    setDataStore(formdata);
    console.log(formdata, "tdsbyd");
    await addBlog(formdata);
    // if (!editapicall) {
    //   await addBlog(formdata);
    // }
    //  else {
    //   await editblog(formDataa);
    //   await getallblogsforadmin();
    // }
  };

  const initialValues = {
    title: "",
    metaDescription: "",
    metaTitle: "",
    keywords: "",
    createdBy: "",
    date: "",
    imageUrl: "",
    blogContent: "",
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    metaDescription: Yup.string().required("Meta Description is required"),
    keywords: Yup.string().required("Keywords is required"),
    createdBy: Yup.string().required("createdBy is required"),
    date: Yup.string().required("date is required"),
    imageUrl: Yup.string().required("image is required"),
    blogContent: Yup.string().required("blogContent is required"),
  });
  // API Integration

  const addBlog = async (data) => {
    try {
      console.log("Data being sent:", data);
      let response = await API.AdminAddBlog({
        title: dataStore.title,
        metaDescription: dataStore.metaDescription,
        metaTitle: dataStore.metaTitle,
        keywords: dataStore.keywords,
        createdBy: dataStore.createdBy,
        imageUrl: imageblog,
        date: dataStore.date,
        blogContent: dataStore.blogContent,
      });
      if (response.data.status === true) {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "",
        });
        setShowSuccessModal(true);
      } else {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "",
        });
        setShowFailureModal(true);
      }
      console.log(response, "rsp");
    } catch (error) {
      console.log(error);
    }
  };
  // delete blog
  const deleteBlog = async (id) => {
    try {
      let deleteres = await API.AdminDeleteBlog(id);
      console.log(deleteres);
      if (deleteres.data.status === true) {
        setMessage({
          msg: deleteres.data.message,
          urlmodaldata: "",
        });
        setShowSuccessModal(true);
      } else {
        setMessage({
          msg: deleteres.data.message,
          urlmodaldata: "",
        });
        setShowFailureModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // pusblish blog
  const PublishBlog = async (id) => {
    try {
      let publishresp = await API.AdminPublishBlog(id);

      if (publishresp.data.status === true) {
        setMessage({
          msg: publishresp.data.message,
          urlmodaldata: "",
        });
        setShowSuccessModal(true);
      } else {
        setMessage({
          msg: publishresp.data.message,
          urlmodaldata: "",
        });
        setShowFailureModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // edit blog
  const handleEditblog = (blog, id) => {
    setEditAPiCall(true);
    setEditingBlog(blog);
    setFormDataa({
      blogId: blog.blogId,
      title: blog.title,
      metadescription: blog.metadescription,
      metatitle: blog.metatitle,
      keywords: blog.keywords,
      createdBy: blog.createdBy,
      imageUrl: blog.imageblog,
      date: blog.date,
      messageBody: blog.messageBody,
    });
  };
  // Handler for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataa((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // get all blogs

  const getallblogsforadmin = async () => {
    try {
      const allblogresp = await API.GetAllblogsforadmin();
      setBlogData(allblogresp.data.result);
      console.log(allblogresp.data.result, "all blog data");
      console.log(blogdata.blogId, "blogid");
      console.log(allblogresp, "gdth");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getallblogsforadmin();
  }, []);
  return (
    <div className="admin-blog mb-5">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className="container mt-5">
              <div className="row adminblogform">
                {/* Title Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="title">Title</label>
                  <Field
                    type="text"
                    name="title"
                    // value={formDataa.title}
                    // onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter title"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Meta Description Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="metaDescription">Meta Description</label>
                  <Field
                    type="text"
                    name="metaDescription"
                    // value={formDataa.metadescription}
                    // onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter meta description"
                  />
                  <ErrorMessage
                    name="metaDescription"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Meta Title Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="metaTitle">Meta Title</label>
                  <Field
                    type="text"
                    name="metaTitle"
                    // value={formDataa.metatitle}
                    // onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter meta title"
                  />
                  <ErrorMessage
                    name="metaTitle"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Keywords Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="keywords">Keywords</label>
                  <Field
                    type="text"
                    name="keywords"
                    // value={formDataa.keywords}
                    // onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter keywords"
                  />
                  <ErrorMessage
                    name="keywords"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Created By Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="createdBy">Created By</label>
                  <Field
                    type="text"
                    name="createdBy"
                    // value={formDataa.createdBy}
                    // onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter author name"
                  />
                  <ErrorMessage
                    name="createdBy"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Upload Image Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="images">Upload Image to Cloudinary</label>{" "}
                  <br />
                  <div className="file-input-wrapper mt-4">
                    <div className="form-group">
                      <input
                        id="more-images-upload"
                        type="file"
                        name="imageUrlTwo"
                        className="input-file"
                        accept="image/*"
                        onChange={(e) => handleFileInputChange(e, "moreImages")}
                        style={{ display: "none" }} // Hide the default file input
                      />
                      <div className="input-group">
                        <span className="input-group-addon"></span>
                        <input
                          type="file"
                          value={formDataa.image}
                          // onChange={handleInputChange}
                          onChange={handleMoreImagesUpload}
                          style={{ marginLeft: 0 }}
                        />

                        <span
                          className="input-group-btn"
                          // value={formDataa.image}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Date Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="date">Date</label>
                  <Field
                    type="date"
                    name="date"
                    className="form-control"
                    // value={formDataa.date}
                    // onChange={handleInputChange}
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* CKEditor Field */}
                <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="blogContent">Blog Content</label>
                  {/* <CKEditor initData="<p>Start writing your blog here...</p>" /> */}
                  <Field
                    as="textarea"
                    name="blogContent"
                    // value={formDataa.messageBody}
                    // onChange={handleInputChange}
                    className="form-control"
                    cols={10}
                    rows={10}
                  />
                  <ErrorMessage
                    name="blogContent"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Submit Button */}
                <div
                  className="form-group col-lg-8 col-md-8 col-12 mt-3 d-flex"
                  style={{ gap: "15px" }}
                >
                  <div>
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => {
                        handleSubmit(values);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-success">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="updated-blogs">
        <div className="container">
          <div className="row">
            {blogdata &&
              blogdata.map(
                (
                  blog,
                  index // Ensure you are mapping over an iterable array
                ) => (
                  <div className="col-12" key={index}>
                    {" "}
                    {/* Use a key for each mapped item */}
                    <div className="updatedblogs">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-12">
                          <div className="updatedblog-image">
                            <img src={blog.image} alt="" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 updated-info">
                          <label htmlFor="">Title</label>
                          <p>{blog.title}</p>{" "}
                          {/* Assuming blog has a title field */}
                          <label htmlFor="">Meta Title</label>
                          <p>{blog.metatitle}</p>{" "}
                          {/* Assuming blog has a metaTitle field */}
                          <label htmlFor="">Meta Description</label>
                          <p>{blog.metadescription}</p>{" "}
                          {/* Assuming blog has a metaDescription field */}
                          <label htmlFor="">Keywords</label>
                          <p>{blog.keywords}</p>{" "}
                          {/* Assuming blog has a keywords field */}
                          <label htmlFor="">Created By</label>
                          <p>{blog.createdBy}</p>{" "}
                          {/* Assuming blog has a createdBy field */}
                          <label htmlFor="">Date</label>
                          <p>{blog.date}</p>{" "}
                          {/* Assuming blog has a date field */}
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-5">
                            <button
                              className="edit"
                              // onClick={() => handleEditblog(blog, blog.blogId)}
                              onClick={() =>
                                handleOpenModal(blog.blogId, blogdata[index])
                              }
                            >
                              Edit
                            </button>
                          </div>

                          <div className="mt-5">
                            <button
                              className="publish"
                              onClick={() => PublishBlog(blog.blogId)}
                            >
                              Publish
                            </button>
                          </div>
                          <div className="mt-5">
                            <button
                              className="delete"
                              onClick={() => deleteBlog(blog.blogId)}
                            >
                              Delete
                            </button>{" "}
                            {/* Assuming deleteBlog function exists */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <EditModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        blogIds={blog}
      />
      <CommonModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message={message}
      />
      <CommonModalFail
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        message={message}
      />
    </div>
  );
};

export default AdminBlogPage;
