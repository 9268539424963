import React, { useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog } from '@fortawesome/free-solid-svg-icons';
import {
  RiDashboardFill,
  RiMenu2Fill,
} from "react-icons/ri";
import { IoMdContact } from "react-icons/io";
import { FaList } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";

import "./AdminSideNav.css";

const AdminSideNav = () => {
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);
  const sidebarRef = useRef(null);
  const location = useLocation(); // Get current location for active link

  const handleMouseEnter = () => {
    setIsSidebarHovered(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarHovered(false);
  };

  return (
    <div 
      className="admin-side-main" 
      ref={sidebarRef} 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      <div className="admin-menu">
        <RiMenu2Fill />
      </div>

      <div className={`d-flex flex-column admin-side-menubar ${isSidebarHovered ? "open" : "closed"}`}>
        <NavLink 
          to="/supernova" 
          className={`admin-side-menubar-dec ${location.pathname === "/supernova" ? "active" : ""}`} // Ensure correct path for active
        >
          <div className="admin-side-menubar-one">
            <RiDashboardFill className="admin-menu-icon" />
            {isSidebarHovered && <span>Dashboard</span>}
          </div>
        </NavLink>
        <NavLink 
          to="/supernova/contact-us" 
          className={`admin-side-menubar-dec ${location.pathname === "/supernova/contact-us" ? "active" : ""}`}
        >
          <div className="admin-side-menubar-one">
            <IoMdContact className="admin-menu-icon" />
            {isSidebarHovered && <span>Contact Us</span>}
          </div>
        </NavLink>
        <NavLink 
          to="/supernova/all-listing" 
          className={`admin-side-menubar-dec ${location.pathname === "/supernova/all-listing" ? "active" : ""}`}
        >
          <div className="admin-side-menubar-one">
            <FaList className="admin-menu-icon" />
            {isSidebarHovered && <span>All Listing</span>}
          </div>
        </NavLink>
        <NavLink 
          to="/supernova/all-accounts" 
          className={`admin-side-menubar-dec ${location.pathname === "/supernova/all-accounts" ? "active" : ""}`}
        >
          <div className="admin-side-menubar-one">
            <MdAccountBalance className="admin-menu-icon" />
            {isSidebarHovered && <span>All Accounts</span>}
          </div>
        </NavLink>
        <NavLink 
          to="/supernova/admin-blog" 
          className={`admin-side-menubar-dec ${location.pathname === "/supernova/admin-blog" ? "active" : ""}`}
        >
          <div className="admin-side-menubar-one">
            <FontAwesomeIcon icon={faBlog} className="admin-menu-icon" />
            {isSidebarHovered && <span>Blog</span>}
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default AdminSideNav;
