import React, { useState, useRef, useEffect } from "react";
import "./Profile.css";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as API from "../../../Components/util/url";
import ListGroup from "react-bootstrap/ListGroup";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import CommonModalFail from "../../../Common/CommonModal/CommonModal";
import { CalculationInterpolation } from "sass";
const Profile = ({ firstName, lastName, phoneNumber, email }) => {
  const [imageSrc, setImageSrc] = useState(
    "https://res.cloudinary.com/dbtsrjssc/image/upload/v1707480887/user_g4lltu.png"
  );

  const [profileData, setProfileData] = useState({
    firstname: localStorage.getItem("firstname") || "",
    lastname: localStorage.getItem("lastname") || "",
    email: localStorage.getItem("email") || "",
    phoneNumber: localStorage.getItem("phoneNumber") || "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [city, setCity] = useState("");
  const [keyword, setKeyword] = useState({});
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const initialValues = {
    firstName: profileData.firstname,
    lastName: profileData.lastname,
    mobileNumber: profileData.phoneNumber,
    email: profileData.email,
    needCare: "",
    careSoon: "",
    searchArea: "",
    careType: "",
    desiredAmenities: "",
    budget: "",
    payCare: "",
    // nurserAssessmentTest: "",
    seniorCareAgent: "",
    // compatibleProviders: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    mobileNumber: Yup.string().required("Mobile Number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    needCare: Yup.string().required("This field is required"),
    careSoon: Yup.string().required("This field is required"),
    // searchArea: Yup.string().required("This field is required"),
    careType: Yup.array().min(1, "At least one care type is required"),
    desiredAmenities: Yup.array().min(1, "At least one amenity is required"),
    budget: Yup.string().required("This field is required"),
    payCare: Yup.string().required("This field is required"),
    // nurserAssessmentTest: Yup.string().required("This field is required"),
    seniorCareAgent: Yup.string().required("This field is required"),
    // compatibleProviders: Yup.string().required("This field is required"),
  });
  // Api integration
  const editprofile = async (data) => {
    try {
      const response = await API.editProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.mobileNumber,
        whoNeedsCare: data.needCare,
        howSoonIsCareNeeded: data.careSoon,
        areaOfSearching: city,
        monthlyBudget: data.budget,
        paymentMethod: data.payCare,
        wantsToBeContactedByAgent: data.seniorCareAgent,
        desiredActivities: data.careType,
        desiredAmenities: data.desiredAmenities,
      });
      if (response.data.status === true) {
        setMessage({ ...message, msg: response.data.message });
        setShowSuccessModal(true);
        // localStorage.setItem("userId", response.data.map.userId);
      }
      if (response.data.cities.length > 0) {
        setKeyword(response.data.cities);
      }
      console.log(response, "profile");
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = (values) => {
    // console.log(values);

    editprofile(values);
  };
  const filterlist = async (data) => {
    try {
      if (data === "") return;

      const response = await API.getCityNames(data);

      console.log("Full API Response:", response);

      const cities =
        response.data &&
        typeof response.data === "object" &&
        Array.isArray(response.data.cities)
          ? response.data.cities
          : {};

      console.log("Cities:", cities);

      setKeyword(cities);
    } catch (error) {
      console.error("API Call Error:", error.message || error);

      setKeyword({});
    }
  };

  const singleCityHandler = (keyword) => {
    setCity(keyword);
    setKeyword([]);
    // cityHandler(keyword, "list");
  };

  const searchHandler = (keyword) => {
    setCity(keyword);
    console.log(keyword, "search handler");
    console.log(city, "setcity");
    let timerOut = setTimeout(() => {
      filterlist(keyword);
    }, 800);
    return () => clearTimeout(timerOut);
  };
  useEffect(() => {
    // Log values from localStorage for debugging
    console.log("Local Storage Values:", {
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
      email: localStorage.getItem("email"),
      phoneNumber: localStorage.getItem("phoneNumber"),
    });
  }, []);
  return (
    <>
      <div className="profile-section">
        <div className="container">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, value }) => (
              <Form>
                <div className="row ">
                  {/* <div className="col-lg-12 col-md-12 col-12 text-center mb-4">
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <img
                        src={imageSrc}
                        alt="Profile"
                        className="profile-user-img"
                        onClick={handleImageClick}
                        style={{
                          cursor: "pointer",
                          borderRadius: "50%",
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                      <span
                        onClick={handleImageClick}
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          background: "white",
                          borderRadius: "50%",
                          padding: "5px",
                          cursor: "pointer",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </span>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name">First Name</label>
                    <br />
                    <Field
                      type="text"
                      name="firstName"
                      // value={value.firstName}
                      className={`form-control ${
                        errors.firstName && touched.firstName
                          ? "input-error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name">Last Name</label>
                    <br />
                    <Field
                      type="text"
                      name="lastName"
                      // value={profileData.lastName}
                      className={`form-control  ${
                        errors.lastName && touched.lastName ? "input-error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name">Mobile Number</label>
                    <br />
                    <Field
                      type="text"
                      name="mobileNumber"
                      // value={profileData.phoneNumber}
                      className={`form-control  ${
                        errors.mobileNumber && touched.mobileNumber
                          ? "input-error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="mobileNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name ">Email</label>
                    <br />
                    <Field
                      type="email"
                      name="email"
                      // value={profileData.email}
                      className={`form-control  ${
                        errors.email && touched.email ? "input-error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name mb-2" htmlFor="needCare">
                      Who Needs Care?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="needCare"
                      className={`form-control ${
                        errors.needCare && touched.needCare ? "input-error" : ""
                      }`}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="Parent">Parent</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Myself">Myself</option>
                      <option value="SomeoneElse">Someone Else</option>
                    </Field>
                    <ErrorMessage
                      name="needCare"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name mb-2" htmlFor="careSoon">
                      How Soon is Care Needed?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="careSoon"
                      className={`form-control ${
                        errors.careSoon && touched.careSoon ? "input-error" : ""
                      }`}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="AsSoonAsPossible">
                        As soon as Possible!
                      </option>
                      <option value="Within30Days">Within 30 Days</option>
                      <option value="1to3Months">1 to 3 Months</option>
                      <option value="3MonthsOrMore">3 Months or More</option>
                    </Field>
                    <ErrorMessage
                      name="careSoon"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name">
                      In What Area are you Searching for Care?
                    </label>
                    <br />
                    <Field
                      type="text"
                      name="searchArea"
                      value={city}
                      onChange={(e) => searchHandler(e.target.value)}
                      className={`form-control ${
                        errors.searchArea && touched.searchArea
                          ? "input-error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="searchArea"
                      component="div"
                      className="text-danger"
                    />
                    {Array.isArray(keyword) && keyword.length !== 0 && (
                      <div
                      //  style={{ position: "absolute", top: 55 }}
                      >
                        <ListGroup>
                          {keyword.map((data) => (
                            <ListGroup.Item
                              key={data}
                              style={{ cursor: "pointer" }}
                              onClick={() => singleCityHandler(data)}
                            >
                              {data}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name mb-2" htmlFor="careType">
                      What Type of Care is Needed?
                    </label>
                    <br />
                    <FieldArray name="careType">
                      {({ push, remove }) => (
                        <>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="type_care_btn"
                            >
                              Select multiple options
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown_menus">
                              {[
                                "ActivitiesCoordinator",
                                "ArtsAndCraft",
                                "Beautician",
                                "BirthdayCelebration",
                                "CableTV",
                                "Games",
                                "InternetAccess",
                                "LibraryOnWheels",
                                "Music",
                                "Newspaper",
                                "Telephone",
                                "WheelchairAccessible",
                                "WheelchairAssist",
                              ].map((type) => (
                                <Dropdown.Item
                                  as="div"
                                  key={type}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Field
                                    type="checkbox"
                                    name="careType"
                                    value={type}
                                  />
                                  <label htmlFor={type}>{type}</label>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="careType"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                    </FieldArray>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label
                      className="Field-name mb-2"
                      htmlFor="desiredAmenities"
                    >
                      What are your Desired Amenities?
                    </label>
                    <br />
                    <FieldArray name="desiredAmenities">
                      {({ push, remove }) => (
                        <>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="type_care_btn"
                            >
                              Select multiple options
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown_menus">
                              {[
                                "AssistanceWithDailyDriving",
                                "AwakeNightShift",
                                "24HourCare",
                                "Dementia/Alzheimer",
                                "DevelopmentDisabilities",
                                "Diabetes",
                                "HomeDoctor",
                                "Hospice",
                                "HouseKeeping/Laundry",
                                "MassageTherapist",
                                "MedicationDelivery",
                                "MedicationManagement",
                                "OxygenTherapy",
                                "Parkinson",
                                "PetCare",
                                "Podiatrist",
                                "PT/OT&SpaTherapy",
                                "RespiteCare/ShortTerm",
                                "RegisteredNurserOperator",
                                "Stroke",
                                "Visual/HearingImpaired",
                                "WoundCare",
                                "DoctorOnSite",
                                "FeedingTube",
                                "2CaregiversOnShift",
                                "3CaregiversOnShift",
                                "CatheterCareProvider",
                                "ColostomyBag",
                                "FemaleResidentPreferred",
                                "MaleResidentPreferred",
                                "HomeOwnerLivesInHouse&ProvidersCare",
                                "TransportationToDoctorProvider",
                                "TransportationToShoppingProvider",
                              ].map((amenity) => (
                                <Dropdown.Item as="div" key={amenity}>
                                  <Field
                                    type="checkbox"
                                    name="desiredAmenities"
                                    value={amenity}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <label htmlFor={amenity}>{amenity}</label>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="desiredAmenities"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                    </FieldArray>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name" htmlFor="budget">
                      Maximum Monthly Budget?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="budget"
                      className={`form-control ${
                        errors.budget && touched.budget ? "input-error" : ""
                      }`}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="BudgetLessThan1500">
                        Less than $1500
                      </option>
                      <option value="Budget1500to2500">$1500 to $2500</option>
                      <option value="Budget2500to3500">$2500 to $3500</option>
                      <option value="Budget3500to4500">$3500 to $4500</option>
                      <option value="Budget4500to5500">$4500 to $5500</option>
                      <option value="Budget5500to6500">$5500 to $6500</option>
                      <option value="Budget6500andMore">$6500 and More</option>
                    </Field>
                    <ErrorMessage
                      name="budget"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name" htmlFor="payCare">
                      How will you Pay for Care ?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="payCare"
                      className={`form-control  ${
                        errors.payCare && touched.payCare ? "input-error" : ""
                      }`}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="PersonalFunds">Personal Funds</option>
                      <option value="Medicaid">Medicaid</option>
                      <option value="PersonalFundsAndMedicaid">
                        Personal Funds & Medicaid
                      </option>
                    </Field>
                    <ErrorMessage
                      name="payCare"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label
                      className="Field-name"
                      htmlFor="nurserAssessmentTest"
                    >
                      Have you Competed a Nurser Assessment Test ?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="nurserAssessmentTest"
                      className={`form-control ${
                        errors.nurserAssessmentTest && touched.nurserAssessmentTest? "input-error":""
                      }`}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Field>
                    <ErrorMessage
                      name="nurserAssessmentTest"
                      component="div"
                      className="text-danger"
                    />
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name" htmlFor="seniorCareAgent">
                      Do you want to be Contacted by a Senior Care Agent ?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="seniorCareAgent"
                      className={`form-control  ${
                        errors.seniorCareAgent && touched.seniorCareAgent
                          ? "input-error"
                          : ""
                      }`}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Field>
                    <ErrorMessage
                      name="seniorCareAgent"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <label className="Field-name" htmlFor="compatibleProviders">
                      Do you want to be Contacted by Compatible Providers ?
                    </label>
                    <br />
                    <Field
                      as="select"
                      name="compatibleProviders"
                     
                      

                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Field>
                    <ErrorMessage
                      name="compatibleProviders"
                      component="div"
                      className="text-danger"
                    />
                  </div> */}
                  <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                    <button type="submit" className="profile-submit-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <CommonModal
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
          message={message}
        />
        <CommonModalFail
          show={showFailureModal}
          onHide={() => setShowFailureModal(false)}
          message={message}
        />
      </div>
    </>
  );
};

export default Profile;
