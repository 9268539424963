import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as API from "../../Components/util/url";
import { Field, Formik, ErrorMessage, Form } from "formik";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

let imageblog = "";

function EditModal({ show, onHide,props,blogIds,blogdata,i }) {
  const location = useLocation();
 
  console.log('Props:',  blogIds );
  // console.log('Props i:',  i );


  const [cloudImage1, setCloudImage1] = useState("");
  const [fileNames, setFileNames] = useState({
    uploadimage: "",
  });
  const [filePreviews, setFilePreviews] = useState({
    uploadimage: "",
  });
  const [image, setImage] = useState("");
  const [blogData, setBlogData] = useState(null);
  const [allblogData, setAllBlogData] = useState([]);
  const [formDataa, setFormDataa] = useState({});
   // Fetch blog data when modal is shown
   useEffect(() => {
    const fetchBlogData = async () => {
      if (blogIds) {
        const response = await API.GetAllblogsforadmin(blogIds); // Make sure this API call is defined
        setBlogData(response.data);
        setAllBlogData(response.data.result[i])
        console.log('Props blog:',response.data.result[i] );
      }
    };

    fetchBlogData();
  }, [blogIds, show]); // Fetch when blogId or modal visibility changes
  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      setFileNames({
        ...fileNames,
        [key]: file.name,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviews({
          ...filePreviews,
          [key]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setFileNames({
        ...fileNames,
        [key]: "",
      });
      setFilePreviews({
        ...filePreviews,
        [key]: "",
      });
    }
  };
  const handleMoreImagesUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      console.log("More images:", URL.createObjectURL(selectedFile));
      setCloudImage1(selectedFile, "uploadimage");
    }
  };
  const handleUpload = async (e) => {
    // setLoading(true);
    console.log("handleUpload");

    console.log(cloudImage1, "ip");
    const file = cloudImage1;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("folder", "AFH/HomeImage");
    // formData.append("public_id", localStorage.getItem("userId"));
    const uniquePublicIdMain = `${localStorage.getItem(
      "userId"
    )}_main_${new Date().getTime()}`;
    formData.append("public_id", uniquePublicIdMain);

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dbtsrjssc/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setImage(data.secure_url);
      console.log(data.secure_url, "url image");
      imageblog = data.secure_url;
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      // setLoading(false);
    }
  };
  const editblog = async (data) => {
    const imageUrl = await handleUpload();
    try {
      let editresp = await API.AdminUpdateBlog({
        blogId: blogIds.blogId,
        title: data.title,
        metaDescription: data.metaDescription,
        metaTitle: data.metaTitle,
        keywords: data.keywords,
        createdBy: data.createdBy,
        imageUrl: imageblog,
        date: data.date,
        blogContent: data.blogContent,
      });

      console.log(editresp, "editresp");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Edit Blog
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Formik
          initialValues={{
            blogId: blogIds?.blogId,
            title: blogdata?.title || blogIds.title,
            metaDescription: blogdata?.metaDescription || blogIds.metadescription,
            metaTitle: blogdata?.metaTitle || blogIds.metatitle,
            keywords: blogdata?.keywords || blogIds.keywords,
            createdBy: blogdata?.createdBy || blogIds.createdBy,
            date: blogdata?.date || blogIds.date,
            blogContent: blogdata?.blogContent || blogIds.messageBody,
          }}
          onSubmit={(values) => {
            // Adding blogId to the values object before submitting
            const payload = {
              ...values,
              blogIds: blogdata?.blogId,  // Ensures blogId is part of the payload
              imageUrl: imageblog,  // Assuming image handling logic exists
            };
            editblog(payload);  // Call the editblog function with the updated payload
          }}
        >
          {({ values,blogData }) => (
            <Form>
              {/* Title Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="title">Title</label>
                <Field
                  type="text"
                  name="title"
                  // value={blogData.title}
                  className="form-control"
                  placeholder="Enter title"
                />
                <ErrorMessage name="title" component="div" className="text-danger" />
              </div>

              {/* Meta Description Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="metaDescription">Meta Description</label>
                <Field
                  type="text"
                  name="metaDescription"
                  className="form-control"
                  placeholder="Enter meta description"
                />
                <ErrorMessage name="metaDescription" component="div" className="text-danger" />
              </div>

              {/* Meta Title Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="metaTitle">Meta Title</label>
                <Field
                  type="text"
                  name="metaTitle"
                  className="form-control"
                  placeholder="Enter meta title"
                />
                <ErrorMessage name="metaTitle" component="div" className="text-danger" />
              </div>

              {/* Keywords Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="keywords">Keywords</label>
                <Field
                  type="text"
                  name="keywords"
                  className="form-control"
                  placeholder="Enter keywords"
                />
                <ErrorMessage name="keywords" component="div" className="text-danger" />
              </div>

              {/* Created By Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="createdBy">Created By</label>
                <Field
                  type="text"
                  name="createdBy"
                  className="form-control"
                  placeholder="Enter author name"
                />
                <ErrorMessage name="createdBy" component="div" className="text-danger" />
              </div>

          {/* Upload Image Field */}
          <div className="form-group col-lg-8 col-md-8 col-12">
                  <label htmlFor="images">Upload Image to Cloudinary</label>{" "}
                  <br />
                  <div className="file-input-wrapper mt-4">
                    <div className="form-group">
                      <input
                        id="more-images-upload"
                        type="file"
                        name="imageUrlTwo"
                        className="input-file"
                        accept="image/*"
                        onChange={(e) => handleFileInputChange(e, "moreImages")}
                        style={{ display: "none" }} // Hide the default file input
                      />
                      <div className="input-group">
                        <span className="input-group-addon"></span>
                        <input
                          type="file"
                          value={formDataa.image}
                          // onChange={handleInputChange}
                          onChange={handleMoreImagesUpload}
                          style={{ marginLeft: 0 }}
                        />

                        <span
                          className="input-group-btn"
                          // value={formDataa.image}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              {/* Date Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="date">Date</label>
                <Field type="date" name="date" className="form-control" />
              </div>

              {/* CKEditor Field */}
              <div className="form-group col-lg-8 col-md-8 col-12">
                <label htmlFor="blogContent">Blog Content</label>
                <Field
                  as="textarea"
                  name="blogContent"
                  className="form-control"
                  cols={10}
                  rows={10}
                />
              </div>

              {/* Submit Button */}
              <div className="form-group col-lg-8 col-md-8 col-12 mt-3 d-flex" style={{ gap: "15px" }}>
                <div>
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
                <div>
                  <button type="button" className="btn btn-secondary" onClick={() => props.onHide()}>Close</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
