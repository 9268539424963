import React from "react";
import "./OwnerAFHServices.css";
import { Table } from "react-bootstrap";

const OwnerAFHServices = () => {
  return (
    <>
      <div style={{ paddingLeft: "5px" }} className="afh-service">
        <div className="afh-services-name ">
          <h2>AFH Services</h2>
        </div>
       
        <div>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="header-width">Name</th>
                <th className="header-width">Company Name</th>
                <th className="header-width">Services</th>
                <th className="header-width">Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dr.Addison</td>
                <td>Northwest Geriatrics</td>
                <td>in home - Doctor</td>
                <td>206-275-3588</td>
              </tr>
              <tr>
                <td>Lois Filipelli</td>
                <td>Modern Optometry</td>
                <td>in home - Optometrist</td>
                <td>360-524-4205</td>
              </tr>
              <tr>
                <td>Larissa Satichtchev</td>
                <td>-</td>
                <td>Music Therapy</td>
                <td>425-373-5450</td>
              </tr>
              <tr>
                <td>Dr.Gorey</td>
                <td>-</td>
                <td>Foot care</td>
                <td>253-370-6129</td>
              </tr>
              <tr>
                <td>Salve</td>
                <td>-</td>
                <td>Games and Activities</td>
                <td>206-313-6697</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default OwnerAFHServices;
