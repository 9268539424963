import React from "react";
import "./Policies.css";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import { Link } from "react-router-dom";
const TermsAndCondition = () => {
  return (
    <div>
      <CommonNavbar style={{ backgroundColor: "#10481A" }} />
      <div className="container">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-12"></div>
          <div className="col-lg-10 col-md-10 col-12">
            <div className="container mb-5 mt-5">
              <div className="policy-heading">
                <h1>Terms and Conditions</h1>
                <hr className="policy-hr" />
                <h2 class="mb-3">
                  1. Agreement between User and Adult Family Home Solutions
                </h2>
                <p>
                  The{" "}
                  <a href="/" className="policy-link">
                    AdultFamilyHomes.org{" "}
                  </a>
                  website ("Site") is composed of various web pages owned and
                  operated by Adult Family Home Solutions, LLC ("AFHS"), a
                  Limited Liability Company registered in the State of
                  Washington, WA since 2017.
                </p>

                <p>
                  The Site is offered to you conditioned on your acceptance
                  without modification of the terms, conditions, and notices
                  contained herein ("Terms").
                </p>
                <p>
                  Use of the Site constitutes your agreement to all such Terms.
                  Please read the Terms carefully, and keep a copy of them for
                  your reference.
                </p>
                <p>
                  The Site provides information services for families looking
                  for care in an adult family home. The Site also provides
                  marketing, placement, and connection services for adult family
                  home owners and clients, and operates as follows:
                </p>

                <h4 class="mb-3">1.1. Information Services</h4>
                <p>
                  Provides free information on services, amenities, and room
                  types of in-network care providers("Providers"). Provides the
                  ability of direct communication between Providers and
                  families/patients("Clients") via public listing pages of
                  Providers.
                </p>
                <h4 class="mb-3">1.2. Marketing Services</h4>
                <p>
                  Gives Providers the opportunity to market their adult family
                  home facility on Site and advertise to prospective clients
                  looking for care in their area.
                </p>
                <h4 class="mb-3">1.3. Placement Services</h4>
                <p>
                  In the case that a Client requests AFHS support in the
                  placement process, AFHS will provide referrals and lead
                  services for Client placements into senior care facilities,
                  for both in-network and out-of-network Providers. AFHS
                  maintains the right to adjust the Placement Fee based on
                  factors such as: financing, complexity of care, desired
                  amenities, and available vacancies. By accepting our Terms and
                  Conditions, Providers hereby understand and accept that AFHS
                  may charge a Placement Fee to fill their vacancies according
                  to the Additional Terms below.
                </p>
                <h4 class="mb-3">1.4. Housing Advisory Services</h4>
                <p>
                  AFHS may charge a Lead Fee to connect Providers and Clients.
                  This is not the same as the typical industry referral fee that
                  is based on actual Client move in and daily rates. The Lead
                  Fee is not and never will be contingent on a Client placement
                  occurring, but rather it enables a Direct Introduction between
                  Providers and Clients. At the exact beginning of a Direct
                  Introduction(first moment Provider and Client have mutually
                  engaged with one another), AFHS no longer receives any
                  compensation for what may or not occur between Provider and
                  Client. AFHS reserves the right to resend Lead to alternative
                  Providers at any which moment.
                </p>
                <h2 class="mb-3">2. Additional Terms</h2>
                <p>
                  The Site is intended as a resource to the senior care
                  community, with the goal of reducing the costs and complexity
                  of finding quality care for both Clients and Providers.
                  Notwithstanding, here is the breakdown of how the Site may
                  generate revenue from its activities:
                </p>
                <h4 class="mb-3">2.1. Clients</h4>
                <p>
                  You will never be asked nor required to pay for any services
                  from the Site, with the exception of optional 3rd party
                  services chosen at your discretion (i.e. Nurse Assessments).
                  By agreeing to these terms and conditions, you are granting
                  AFHS the right to share medical data provided by you to
                  in-network and out-of-network care Providers.
                </p>
                <h4 class="mb-3">2.2. Providers</h4>
                <p>
                  The Provider must be appropriately licensed in the
                  state/county/city in which the Provider is located. Listing on
                  the site is completely free of charge. Providers are
                  encouraged to list their care facility on the Site in order to
                  effectively market their business. AFHS reserves the right to
                  charge a Lead Fee for connecting Providers with Clients.
                  Providers are solely responsible for this cost. Leads are
                  newly signed up Clients that score a positive proprietary
                  compatibility score with Providers. AFHS observes the right to
                  send compatible Providers notifications and options to obtain
                  contact information of Clients.
                </p>
                <p>
                  Housing Referral is defined as a Client placement into a
                  Provider facility as a direct result of an AFHS Authorized
                  Agent connecting both parties together. Due to federal
                  regulations, AFHS does not provide Housing Referral services
                  to solely Medicaid Clients
                </p>
                <h4 class="mb-3">2.2.1. Fees:</h4>
                <p>
                  The Provider or Providers representative agrees to pay to AFHS
                  a Referral Fee based on the following scenarios:
                </p>
                <h4 class="mb-3">2.2.1.1. Scenario 1:</h4>
                <p>
                  One payment of equivalence to 80% of one months housing and
                  care fees charged to the Client for each private pay
                  placement, due 31 days after the effective move-in date.
                </p>
                <h4 class="mb-3">2.2.1.2. Scenario 2:</h4>
                <p>
                  Three payments whose sum is equivalent to 100% of one months
                  housing and care fees charged to the Client for each private
                  pay placement and according to following schedule: One third
                  of Referral Fee, due 31 days after the effective move-in date.
                  One third of Referral Fee, due 61 days after the effective
                  move-in date. One third of Referral Fee, due 91 days after the
                  effective move-in date.
                </p>
                <h4 class="mb-3">2.2.2. Grace Period:</h4>
                <p>
                  If, for any reason, the Client moves out of the property or
                  passes away within the first thirty (30) days of residency,
                  there is no fee due payable to AFHS.
                </p>
                <h4 class="mb-3">2.2.3. Respite/Hospice:</h4>
                <p>
                  The Referral Fee for Respite and Hospice clients is 20% of the
                  clients monthly fee for each month in residency/care up to and
                  including the 5th month. AFHS notifies the ACH whether the
                  client is Respite or Hospice prior to move-in.
                </p>
                <h4 class="mb-3">2.2.4. Payment Terms:</h4>
                <p>The Referral Fee will be invoiced monthly by AFHS.</p>
                <p>
                  Invoices are due on the date indicated on the invoice. Late
                  fees will be assessed on all invoices that are past due. Late
                  fees accrue at a rate of 5% of the invoiced amount per month
                  or fraction of a month late. Invoices 60 days or more past the
                  due date may be turned over to a collections agency.
                </p>
                <p>
                  If Provider is referred to collections for outstanding payment
                  obligations defined in sections 2.2.1 and 2.2.3, Provider will
                  be charged: Late fees as defined in section 2.2.4; and all
                  collections fees or costs incurred; and/or any and all
                  attorney fees and costs incurred by AFHS in the event legal
                  action is taken.
                </p>
                <h4 class="mb-3">2.2.5. Additional:</h4>
                <p>
                  AFHS does not pay for the expense of the nurse assessment. It
                  is the responsibility of the Provider and/or the Client to pay
                  for said expense.
                </p>
                <h2 class="mb-3">3. Privacy</h2>
                <p>
                  Your use of the Site is subject to AFHS Privacy Policy. Please
                  review our{" "}
                  <Link to="https://adultfamilyhomes.org/privacyPolicy" target="__blank">
                    Privacy Policy
                  </Link>
                  , which also governs the Site and informs users of our data
                  collection practices.
                </p>
                <h2 class="mb-3">4. Electronic Communications</h2>
                <p>
                  Visiting the Site or sending emails to AFHS constitutes
                  electronic communications. You consent to receive electronic
                  communications and you agree that all agreements, notices,
                  disclosures and other communications that AFHS provides to you
                  electronically, via email and on the Site, satisfy any legal
                  requirement that such communications be in writing.
                </p>
                <h2 class="mb-3">5. Your Account</h2>
                <p>
                  If you use this site, you are responsible for maintaining the
                  confidentiality of your account and password and for
                  restricting access to your computer, and you agree to accept
                  responsibility for all activities that occur under your
                  account or password.
                </p>
                <p>
                  You may not assign or otherwise transfer your account to any
                  other person or entity.
                </p>
                <p>
                  You acknowledge that AFHS is not responsible for third party
                  access to your account that results from theft or
                  misappropriation of your account. AFHS and its associates
                  reserve the right to refuse or cancel service, terminate
                  accounts, or remove or edit content in our sole discretion.
                </p>
                <h2 class="mb-3">6. Children Under Thirteen</h2>
                <p>
                  AFHS does not knowingly collect, either online or offline,
                  personal information from persons under the age of thirteen.
                  If you are under 18, you may use the Site only with permission
                  of a parent or guardian.
                </p>
                <h2 class="mb-3">7. Cancellation/Refund Policy</h2>
                <p>
                  Please view our{" "}
                  <Link to="https://adultfamilyhomes.org/cancellationPolicy" target="__blank">
                    Cancellation Policy
                  </Link>{" "}
                  for details on cancellations and/or refunds.
                </p>
                <h2 class="mb-3">
                  8. Links to Third Party Sites/Third Party Services
                </h2>
                <p>
                  The Site may contain links to other websites ("Linked Sites").
                  The Linked Sites are not under the control of AFHS and AFHS is
                  not responsible for the contents of any Linked Site, including
                  without limitation any link contained in a Linked Site, or any
                  changes or updates to a Linked Site.
                </p>
                <p>
                  AFHS is providing these links to you only as a convenience,
                  and the inclusion of any link does not imply endorsement by
                  AFHS of the Linked Site or any association with its operators.
                </p>
                <p>
                  Certain services made available via the Site are delivered by
                  third party sites and organizations. By using any product,
                  service or functionality originating from the Site domain, you
                  hereby acknowledge and consent that AFHS may share such
                  information and data with any third party with whom AFHS has a
                  contractual relationship to provide the requested product,
                  service or functionality on behalf of the Site Users and
                  customers.
                </p>
                <h2 class="mb-3">
                  9. No Unlawful or Prohibited Use/Intellectual Property
                </h2>
                <p>
                  You are granted a non-exclusive, non-transferable, revocable
                  license to access and use the Site strictly in accordance with
                  these Terms. As a condition of your use of the Site, you
                  warrant to AFHS that you will not use the Site for any purpose
                  that is unlawful or prohibited by these Terms. You may not use
                  the Site in any manner which could damage, disable,
                  overburden, or impair the Site or interfere with any other
                  party's use and enjoyment of the Site. You may not obtain or
                  attempt to obtain any materials or information through any
                  means not intentionally made available or provided for through
                  the Site.
                </p>
                <p>
                  All content included as part of the Site, such as text,
                  graphics, logos, images, as well as the compilation thereof,
                  and any software used on the Site, is the property of AFHS or
                  its suppliers and protected by copyright and other laws that
                  protect intellectual property and proprietary rights. You
                  agree to observe and abide by all copyright and other
                  proprietary notices, legends or other restrictions contained
                  in any such content and will not make any changes thereto.
                </p>
                <p>
                  You will not modify, publish, transmit, reverse engineer,
                  participate in the transfer or sale, create derivative works,
                  or in any way exploit any of the content, in whole or in part,
                  found on the Site. AFHS content is not for resale. Your use of
                  the Site does not entitle you to make any unauthorized use of
                  any protected content, and in particular, you will not delete
                  or alter any proprietary rights or attribution notices in any
                  content. You will use protected content solely for your
                  personal use and will make no other use of the content without
                  the express written permission of AFHS and the copyright
                  owner. You agree that you do not acquire any ownership rights
                  in any protected content. We do not grant you any licenses,
                  express or implied, to the intellectual property of AFHS or
                  our licensors except as expressly authorized by these Terms.
                </p>
                <h2 class="mb-3">10. Use of Communication Services</h2>
                <p>
                  The Site may contain bulletin board services, chat areas, news
                  groups, forums, communities, personal web pages, calendars,
                  and/or other message or communication facilities designed to
                  enable you to communicate with the public at large or with a
                  group (collectively, "Communication Services"). You agree to
                  use the Communication Services only to post, send and receive
                  messages and material that are proper and related to the
                  particular Communication Service.
                </p>
                <p>
                  By way of example, and not as a limitation, you agree that
                  when using a Communication Service, you will not: defame,
                  abuse, harass, stalk, threaten or otherwise violate the legal
                  rights (such as rights of privacy and publicity) of others;
                  publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, infringing, obscene,
                  indecent or unlawful topic, name, material or information;
                  upload files that contain software or other material protected
                  by intellectual property laws (or by rights of privacy of
                  publicity) unless you own or control the rights thereto or
                  have received all necessary consents; upload files that
                  contain viruses, corrupted files, or any other similar
                  software or programs that may damage the operation of
                  another's computer; advertise or offer to sell or buy any
                  goods or services for any business purpose, unless such
                  Communication Service specifically allows such messages;
                  conduct or forward surveys, contests, pyramid schemes or chain
                  letters; download any file posted by another user of a
                  Communication Service that you know, or reasonably should
                  know, cannot be legally distributed in such manner; falsify or
                  delete any author attributions, legal or other proper notices
                  or proprietary designations or labels of the origin or source
                  of software or other material contained in a file that is
                  uploaded; restrict or inhibit any other user from using and
                  enjoying the Communication Services; violate any code of
                  conduct or other guidelines which may be applicable for any
                  particular Communication Service; harvest or otherwise collect
                  information about others, including e-mail addresses, without
                  their consent; violate any applicable laws or regulations
                </p>
                <p>
                  AFHS has no obligation to monitor the Communication Services.
                  However, AFHS reserves the right to review materials posted to
                  a Communication Service and to remove any materials in its
                  sole discretion. AFHS reserves the right to terminate your
                  access to any or all of the Communication Services at any time
                  without notice for any reason whatsoever.
                </p>
                <p>
                  AFHS reserves the right at all times to disclose any
                  information as necessary to satisfy any applicable law,
                  regulation, legal process or governmental request, or to edit,
                  refuse to post or to remove any information or materials, in
                  whole or in part, at AFHS sole discretion.
                </p>
                <p>
                  Always use caution when giving out any personally identifying
                  information about yourself or your children in any
                  Communication Service. AFHS does not control or endorse the
                  content, messages or information found in any Communication
                  Service and, therefore, AFHS specifically disclaims any
                  liability with regard to the Communication Services and any
                  actions resulting from your participation in any Communication
                  Service. Managers and hosts have not authorized AFHS
                  spokespersons, and their views do not necessarily reflect
                  those of AFHS.
                </p>
                <p>
                  Materials uploaded to a Communication Service may be subject
                  to posted limitations on usage, reproduction and/or
                  dissemination. You are responsible for adhering to such
                  limitations if you upload the materials.
                </p>

                <h2 class="mb-3">
                  11. Materials Provided to the Site or Posted on Any AFHS Web
                  Page
                </h2>
                <p>
                  AFHS does not claim ownership of the materials you provide to
                  the Site (including feedback and suggestions) or post, upload,
                  input or submit to any AFHS Web Page or our associated
                  services (collectively "Submissions"). However, by posting,
                  uploading, inputting, providing or submitting your Submission
                  you are granting AFHS, our affiliated companies and necessary
                  sublicensees permission to use your Submission in connection
                  with the operation of their Internet businesses including,
                  without limitation, the rights to: copy, distribute, transmit,
                  publicly display, publicly perform, reproduce, edit, translate
                  and reformat your Submission; and to publish your name in
                  connection with your Submission.
                </p>
                <p>
                  No compensation will be paid with respect to the use of your
                  Submission, as provided herein. AFHS is under no obligation to
                  post or use any Submission you may provide and may remove any
                  Submission at any time at AFHS sole discretion.
                </p>
                <p>
                  By posting, uploading, inputting, providing or submitting your
                  Submission you warrant and represent that you own or otherwise
                  control all of the rights to your Submission as described in
                  this section including, without limitation, all the rights
                  necessary for you to provide, post, upload, input or submit
                  the Submissions.
                </p>
                <h2 class="mb-3">12. International Users</h2>
                <p>
                  The Site is controlled, operated and administered by AFHS from
                  our offices within the USA. If you access the Site from a
                  location outside the USA, you are responsible for compliance
                  with all local laws. You agree that you will not use the AFHS
                  Content accessed through the Site in any country or in any
                  manner prohibited by any applicable laws, restrictions or
                  regulations.
                </p>
                <h2 class="mb-3">13. Indemnification</h2>
                <p>
                  You agree to indemnify, defend and hold harmless AFHS, its
                  officers, directors, employees, agents and third parties, for
                  any losses, costs, liabilities and expenses (including
                  reasonable attorney's fees) relating to or arising out of your
                  use of or inability to use the Site or services, any user
                  postings made by you, your violation of any terms of this
                  Agreement or your violation of any rights of a third party, or
                  your violation of any applicable laws, rules or regulations.
                  AFHS reserves the right, at its own cost, to assume the
                  exclusive defense and control of any matter otherwise subject
                  to indemnification by you, in which event you will fully
                  cooperate with AFHS in asserting any available defenses.
                </p>
                <h2 class="mb-3">14. Arbitration</h2>
                <p>
                  In the event the parties are not able to resolve any dispute
                  between them arising out of or concerning these Terms and
                  Conditions, or any provisions hereof, whether in contract,
                  tort, or otherwise at law or in equity for damages or any
                  other relief, then such dispute shall be resolved only by
                  final and binding arbitration pursuant to the Federal
                  Arbitration Act, conducted by a single neutral arbitrator and
                  administered by the American Arbitration Association, or a
                  similar arbitration service selected by the parties, in a
                  location mutually agreed upon by the parties. The arbitrator's
                  award shall be final, and judgment may be entered upon it in
                  any court having jurisdiction. In the event that any legal or
                  equitable action, proceeding or arbitration arises out of or
                  concerns these Terms and Conditions, the prevailing party
                  shall be entitled to recover its costs and reasonable
                  attorney's fees. The parties agree to arbitrate all disputes
                  and claims in regards to these Terms and Conditions or any
                  disputes arising as a result of these Terms and Conditions,
                  whether directly or indirectly, including Tort claims that are
                  a result of these Terms and Conditions. The parties agree that
                  the Federal Arbitration Act governs the interpretation and
                  enforcement of this provision. The entire dispute, including
                  the scope and enforceability of this arbitration provision
                  shall be determined by the Arbitrator. This arbitration
                  provision shall survive the termination of these Terms and
                  Conditions.
                </p>

                <h2 class="mb-3">15. Class Action Waiver</h2>
                <p>
                  Any arbitration under these Terms and Conditions will take
                  place on an individual basis; class arbitrations and
                  class/representative/collective actions are not permitted. THE
                  PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
                  ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                  CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                  REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                  ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless
                  both you and AFHS agree otherwise, the arbitrator may not
                  consolidate more than one person's claims, and may not
                  otherwise preside over any form of a representative or class
                  proceeding.
                </p>
                <h2 class="mb-3">16. Liability Disclaimer</h2>
              
                <p>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            ADULT FAMILY HOME SOLUTIONS LLC AND/OR ITS SUPPLIERS MAY MAKE
            IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
          </p>
          <p>
            ADULT FAMILY HOME SOLUTIONS LLC AND/OR ITS SUPPLIERS MAKE NO
            REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
            TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
            SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE.
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
            PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. ADULT
            FAMILY HOME SOLUTIONS LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL
            WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
            WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            ADULT FAMILY HOME SOLUTIONS LLC AND/OR ITS SUPPLIERS BE LIABLE FOR
            ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
            DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
            DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
            WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
            DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
            PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION,
            SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH
            THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER
            BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
            EVEN IF ADULT FAMILY HOME SOLUTIONS LLC OR ANY OF ITS SUPPLIERS HAS
            BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
            STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
            PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </p>
          <h2 class="mb-3">17. Termination/Access Restriction</h2>

          <p>
            AFHS reserves the right, in its sole discretion, to terminate your
            access to the Site and the related services or any portion thereof
            at any time, without notice. To the maximum extent permitted by law,
            this agreement is governed by the laws of the State of Washington
            and you hereby consent to the exclusive jurisdiction and venue of
            courts in Washington in all disputes arising out of or relating to
            the use of the Site. Use of the Site is unauthorized in any
            jurisdiction that does not give effect to all provisions of these
            Terms, including, without limitation, this section.
          </p>

          <p>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and AFHS as a result of this
            agreement or use of the Site. AFHS' performance of this agreement is
            subject to existing laws and legal process, and nothing contained in
            this agreement is in derogation of AFHS' right to comply with
            governmental, court and law enforcement requests or requirements
            relating to your use of the Site or information provided to or
            gathered by AFHS with respect to such use. If any part of this
            agreement is determined to be invalid or unenforceable pursuant to
            applicable law including, but not limited to, the warranty
            disclaimers and liability limitations set forth above, then the
            invalid or unenforceable provision will be deemed superseded by a
            valid, enforceable provision that most closely matches the intent of
            the original provision and the remainder of the agreement shall
            continue in effect.
          </p>

          <p>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and AFHS with respect to the Site
            and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            AFHS with respect to the Site. A printed version of this agreement
            and of any notice given in electronic form shall be admissible in
            judicial or administrative proceedings based upon or relating to
            this agreement to the same extent and subject to the same conditions
            as other business documents and records originally generated and
            maintained in printed form. It is the express wish to the parties
            that this agreement and all related documents be written in English.
          </p>
          <h2 class="mb-3">18. Changes to Terms</h2>

          <p>
							AFHS reserves the right, in its sole discretion, to
							change the Terms under which the Site 
							is offered with or without notification. The most current version of the Terms will supersede
							all previous versions. AFHS encourages you to
							periodically review the Terms to stay informed of our updates.
						</p>
            <h2 class="mb-3">Contact Us</h2>
            <p>AFH Solutions welcomes your questions or comments regarding
							the Terms:</p>
              <div>
							<h5>Adult Family Home Solutions, LLC</h5>
							<h5>
								Support: <a href="mailto:support@adultfamilyhomes.org" className="policy-link">support@adultfamilyhomes.org</a>
							</h5>
							<h5>Effective as of August 02, 2022</h5>
						</div>
              </div>
            </div>
          
          <div className="col-lg-1 col-md-1 col-12"></div>
      
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
