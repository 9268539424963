import React, { useState } from 'react'
import './SearchDashboard.css'

const SearchDashboard = ({onSearch,onDelete}) => {
  const handleInputChange = (e) => {
    console.log("dhfbhkdg");
    onSearch(e.target.value); 
  };
  return (
    <div>
      <div className="container">
      <div className="searchbardiv d-flex">
   
    <div className="searchInputWrapper">
      <input
        className="searchInput"
        type="text"
        placeholder="search here"
       onChange={handleInputChange}
      />
      <i className="searchInputIcon fa fa-search" />
    </div>
    <div className="delete-btn">
        <button
        onClick={onDelete}
        ><i class="fa-solid fa-trash"></i></button>
    </div>
  </div>
      </div>
    </div>
  )
}

export default SearchDashboard
