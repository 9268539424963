import React from "react";
import "./Policies.css";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
const PrivacyPolicy = () => {
  return (
    <div>
      <CommonNavbar style={{ backgroundColor: "#10481A" }} />
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="policy-heading">
            <h1>Privacy Policy</h1>
          </div>
          <hr className="policy-hr" />
          <p className="policy-para">
            Protecting your private information is our priority. This Statement
            of Privacy applies to adultfamilyhomes.org ("Site") and Adult Family
            Home Solutions LLC ("AFHS") and governs data collection and usage.
            For the purposes of this Privacy Policy, unless otherwise noted, all
            references to AFHS include adultfamilyhomes.org and AFHS. The Site
            is a senior care home directory website. By using the Site, you
            consent to the data practices described in this statement.
          </p>
          <h4 className="privacy-h4">Collection of your Personal Information</h4>
          <p className="policy-para">
            Please keep in mind that if you directly disclose personally
            identifiable information or personally sensitive data through the
            Site public message boards, this information may be collected and
            used by others. <br />We do not collect any personal information about you
            unless you voluntarily provide it to us. However, you may be
            required to provide certain personal information to us when you
            elect to use certain products or services available on the Site.
            These may include: (a) registering for an account on our Site; (b)
            entering a sweepstakes or contest sponsored by us or one of our
            partners; (c) signing up for special offers from selected third
            parties; (d) sending us an email message; (e) submitting your credit
            card or other payment information when ordering and purchasing
            products and services on our Site. To with, we will use your
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </p>

          <h4 className="privacy-h4">Use of your Personal Information</h4>
          <p className="policy-para">
            AFHS collects and uses your personal information to operate its
            website(s) and deliver the services you have requested. AFHS may
            also use your personally identifiable information to inform you of
            other products or services available from AFHS and its affiliates.
            AFHS may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on AFHS or the site; (b) protect
            and defend the rights or property of AFHS; and/or (c) act under
            exigent circumstances to protect the personal safety of users of
            AFHS, or the public.
          </p>

          <h4 className="privacy-h4">Sharing Information with Third Parties</h4>
          <p className="policy-para">AFHS does not sell, rent or lease its customer lists to third parties.
AFHS may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services, and they are required to maintain the confidentiality of your information. <br />
Automatically Collected Information
Information about your computer hardware and software may be automatically collected by AFHS. This information can include your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain the quality of the service, and to provide general statistics regarding use of the Site.</p>


<h4 className="privacy-h4">Use of Cookies</h4>
<p className="policy-para">
The Site may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. <br />
One of the primary purposes of cookies is to provide a convenience feature to save your time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Site pages, or register with the Site or services, a cookie helps AFHS to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the Site, the information you previously provided can be retrieved, so you can easily use the Site features that you customized. <br />
You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Sites services or websites you visit.
</p>

<h4 className="privacy-h4">Links</h4>
<p className="policy-para">The Site contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave the Site and to read the privacy statements of any other site that collects personally identifiable information.</p>

<h4 className="privacy-h4">Security of your Personal Information</h4>
<p className="policy-para">AFHS secures your personal information from unauthorized access, use, or disclosure. AFHS uses the following methods for this purpose: - SSL Protocol <br />
When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. <br />
We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>

<h4 className="privacy-h4">Children Under Thirteen</h4>
<p className="policy-para">AFHS does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use the Site.</p>

<h4 className="privacy-h4">E-mail Communications</h4>
<p className="policy-para">From time to time, AFHS may contact you via email, call, or text for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. <br />
If you would like to stop receiving marketing or promotional communications via email from AFHS, you may opt-out of such communications by Customers may unsubscribe from emails by clicking on the UNSUBSCRIBE button.</p>

<h4 className="privacy-h4">External Data Storage Sites</h4>
<p className="policy-para">We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>

<h4 className="privacy-h4">Changes to this Statement</h4>
<p className="policy-para">AFHS reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

<h4 className="privacy-h4">Contact Information</h4>
<p className="policy-para">AFHS welcomes your questions or comments regarding this Statement of Privacy. If you believe that AFHS has not adhered to this Statement, please contact S at:
</p>
<b>Adult Family Home Solutions LLC<br/></b>

<p ><b>Support: <a href="mailto:support@adultfamilyhomes.org" className="policy-link">support@adultfamilyhomes.org</a></b></p>
<b>Effective as of August 02, 2022<br/></b>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
