import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import './CommonModal.css';

const CommonModalFail = (props) => {
  const navigate = useNavigate();

  // Ensure that props.message is defined before destructuring
  const { msg = '', urlmodaldata = '' } = props.message || {};

  const closeModal = () => {
    if (urlmodaldata !== '') {
      navigate(urlmodaldata);
    }
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className='modalfooter'></Modal.Header>
        <Modal.Body className='succ-modal__text'>
          <div className="succ-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="58"
              height="58"
              viewBox="0 0 48 48"
            >
              <path
                fill="#f44336"
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              ></path>
              <path
                fill="#fff"
                d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
              ></path>
              <path
                fill="#fff"
                d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
              ></path>
            </svg>
          </div>
          <p>{msg}</p> {/* Display the message here */}
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
          <Button onClick={closeModal} className="commanmodalbtn" style={{ background: "#F44336", border: "none", width: "100%" }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CommonModalFail;
