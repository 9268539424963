import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const role = localStorage.getItem('Role'); 
  const token = localStorage.getItem('afhnew'); 

  useEffect(() => {
    if (!token || role !== "ROLE_ADMIN") {
      navigate('/signin'); 
    }
  }, [navigate, role, token]);

 
  if (token && role === "ROLE_ADMIN") {
    return children;
  }


  return null;
};

export default ProtectedRoutes;
