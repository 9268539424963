import React from "react";

const Medicaid = () => {
  return (
    <div>
      <div className="medicaid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="medicaid_head">
                <h1>Medicaid</h1>
              </div>
              <div className="medicaid-data">
                <p>
                  Medicaid is a government health insurance program available to
                  people with very limited income and resources. Medicaid does
                  not pay money to you. It sends payments directly to your
                  health care providers. Medicaid can pay for medical services
                  in your own home or if you live in a residential care facility
                  that takes Medicaid residents.
                </p>
                <p>
                  Adult Family Homes are regular neighborhood homes where staff
                  assumes responsibility for the safety and well-being of the
                  adult. A room, meals, laundry, supervision and varying levels
                  of assistance with care are provided. Some provide occasional
                  nursing care. Some offer specialized care for people with
                  mental health issues, developmental disabilities or dementia.
                  The home can have two to eight residents and is licensed by
                  the state.
                </p>

                <p>
                  Medicaid will pay for care only when someone becomes
                  financially eligible. There are many Adult Family Homes
                  (AFH's) in Washington State that accept Medicaid. There are
                  some AFH's that will accept a resident upon admission that has
                  Medicaid as a form of payment but these homes may be full as
                  the need is high. The majority of AFH's will require a new
                  resident to pay privately for a set amount of time before
                  transitioning the resident to medicaid. This is referred to as
                  medicaid spend down. The time required for private pay can
                  range from 6 months to 3+ years or until all the residents'
                  funds are exhausted and the resident becomes "financially
                  eligible" for medicaid .
                </p>
              </div>
            </div>
            <hr className="sectionline" />
          </div>
          <div className="row mt-3">
            <div className="medicaid_head">
              <h1>Find A Place You Can Call Home</h1>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <div className="medicaid-text">
                <p data-aos="fade-right">
                  We believe your loved one deserves the best care possible and
                  our mission is to provide a comprehensive and intuitive
                  platform that allows you to educate yourself on different
                  Adult Family Homes in your area.
                </p>
                <p data-aos="fade-right">
                  Planning for long term care alone can be a daunting task and
                  with the sensitive nature of the subject, it can become very
                  disheartening on how to best search through the plethora of
                  options in a massive healthcare industry.
                </p>
                <p data-aos="fade-right">
                  Adult Family Homes Solutions takes away this highly stressful
                  process of finding living and care options in your area.
                  Through our Senior Care Specialist we have created an "all in
                  one" platform that gives you the information you need.
                </p>
                <p data-aos="fade-right">
                  Our search tool allows you to find specific care and lifestyle
                  requirements based on an area of interest and shortens the
                  time it takes to get in touch with a provider, minimizing the
                  stress of finding a place you can call home for your loved
                  one.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-3">
              <div
                className="medicaid-img"
                data-aos="zoom-in-up"
                data-aos-duration="3000"
              >
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720185330/medicaid_lgk8by.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Medicaid;
