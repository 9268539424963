import React, { useState } from "react";
// import "./AllListing.css";
import SearchDashboard from "../../../Common/searchdashboard/SearchDashboard";
import PaginationWrapper from "../../../Common/Pagination/Pagination";

const ApplicationProvider = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [size, setSize] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  //
  const listings = [
    {
      srNo: 1,
      UID: "My Home",
      Name: "My Home",
      Email: "ahmedgulfam113@gmail.com",

      PhoneNumber: "123456789",
      MedicalBackground: "yes",
      IntrestedIn: "Provider",
      deleteIcon: "fa-regular fa-trash-can",
    },
    {
        srNo: 1,
        UID: "My Home",
        Name: "My Home",
        Email: "ahmedgulfam113@gmail.com",
  
        PhoneNumber: "123456789",
        MedicalBackground: "yes",
        IntrestedIn: "Provider",
        deleteIcon: "fa-regular fa-trash-can",
      },
    // Add more rows as needed
  ];

  return (
    <div className="mt-5  m-auto container">
      <div className="container seachbar m-auto  ">
        <SearchDashboard style={{ marginBottom: "20px" }} />
      </div>
      <div className="table_responsive mt-5">
        <table className="tablemain">
          <thead className="tableheader">
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="table-checkbox"
                  id="table-checkbox-all"
                />
              </th>
              <th>Sr.No</th>
              <th>UID</th>
              <th>Name</th>
              <th> Email</th>
              <th>Phone Number</th>
              <th>Medical Background</th>
              <th>Intrested In</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {listings.map((listing, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    name="table-checkbox"
                    id={`table-checkbox-${index}`}
                  />
                </td>
                <td>{listing.srNo}</td>
                <td>{listing.UID}</td>
                <td>{listing.Name}</td>
                <td>{listing.Email}</td>

                <td>{listing.PhoneNumber}</td>

                <td>{listing.MedicalBackground}</td>
                <td>{listing.IntrestedIn}</td>

                <td>
                  <i className={listing.deleteIcon}></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationWrapper
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  );
};

export default ApplicationProvider;
