import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState:{
        authState:false,
        userName:'',
        accessToken:null
    },
    reducers:{
        setAuth: (state, { payload }) => {
            state.accessToken = payload.accessToken;
            state.userName= payload.userName;
            state.authState = true; 
            
          },
    }
})

export const {setAuth} = authSlice.actions
export default authSlice.reducer;
export const isLoggedIn = (state) => state.auth?.authState; 
